import generateUniqueId from "../../../utils/generateId"
import DataProps from "../../ListFast/Components/DndKit/utils/DataProps"

const HeroWithImage: () => DataProps = () => {
    return {
        "id": generateUniqueId(),
        "index": 5,
        "htmlTag": "section",
        "children": [
            {
                "id": generateUniqueId(),
                "index": 0,
                "htmlTag": "div",
                "children": [
                    {
                        "id": generateUniqueId(),
                        "index": 0,
                        "htmlTag": "div",
                        "children": [
                            {
                                "id": generateUniqueId(),
                                "text": "Unlock the Power of Effortless Web Development",
                                "index": 0,
                                "htmlTag": "h1",
                                "children": [],
                                "className": [
                                    "text-4xl",
                                    "font-bold",
                                    "tracking-tighter",
                                    "sm:text-5xl",
                                    "xl:text-6xl/none"
                                ]
                            },
                            {
                                "id": generateUniqueId(),
                                "text": "Our platform empowers you to build stunning, high-performing websites with ease. Say goodbye to complex configurations and hello to rapid innovation.",
                                "index": 1,
                                "htmlTag": "p",
                                "children": [],
                                "className": [
                                    "max-w-[600px]",
                                    "text-muted-foreground",
                                    "md:text-xl"
                                ]
                            }
                        ],
                        "className": [
                            "space-y-2"
                        ]
                    },
                    {
                        "id": generateUniqueId(),
                        "index": 1,
                        "htmlTag": "div",
                        "children": [
                            {
                                "id": generateUniqueId(),
                                "text": "Get started",
                                "index": 0,
                                "htmlTag": "button",
                                "children": [],
                                "className": [
                                    "rounded-md",
                                    "py-2",
                                    "px-10",
                                    "text-primary-foreground",
                                    "bg-primary",
                                    "font-semibold",
                                    "hover:bg-opacity-80",
                                    "transition"
                                ]
                            },
                            {
                                "id": generateUniqueId(),
                                "text": "Learn more",
                                "index": 1,
                                "htmlTag": "button",
                                "children": [],
                                "className": [
                                    "rounded-md",
                                    "py-2",
                                    "px-10",
                                    "border",
                                    "border-input",
                                    "bg-background",
                                    "font-semibold",
                                    "hover:bg-opacity-80",
                                    "transition"
                                ]
                            }
                        ],
                        "className": [
                            "items-center",
                            "flex",
                            "gap-4"
                        ]
                    },
                    {
                        "id": generateUniqueId(),
                        "text": "Sign up to unlock the full potential of our platform.",
                        "index": 2,
                        "htmlTag": "p",
                        "children": [],
                        "className": [
                            "text-xs",
                            "text-muted-foreground"
                        ]
                    }
                ],
                "className": [
                    "flex",
                    "flex-col",
                    "justify-center",
                    "space-y-4"
                ]
            },
            {
                "id": generateUniqueId(),
                "index": 1,
                "htmlTag": "img",
                "children": [],
                "className": [
                    "sm:w-full",
                    "lg:order-last",
                    "mx-auto",
                    "aspect-square",
                    "overflow-hidden",
                    "rounded-xl",
                    "object-cover",
                    "object-center",
                ],
                "src": "https://g-jy6rem82yqk.vusercontent.net/placeholder.svg",
                "width": "550",
                "height": "550"
            }
        ],
        "className": [
            "container",
            "px-4",
            "md:px-6",
            "grid",
            "gap-6",
            "lg:grid-cols-[1fr_400px]",
            "lg:gap-12",
            "xl:grid-cols-[1fr_600px]",
            "py-12",
            "md:py-18",
            "text-foreground",
            "lg:py-24"
        ]
    }
}

export default HeroWithImage
