import LinkFooter from "./pages/Templates/Footers/LinkFooter";
import React from "react";

export default function Sandbox() {
  function renderSection(section: any) {
    const voidElements = ['input', 'img', 'br', 'hr', 'meta', 'link', 'base', 'col', 'command', 'keygen', 'param', 'source', 'track', 'wbr'];

    return React.createElement(
        section.htmlTag,
        {
            key: section.id,
            className: section.className?.join(' ') || '',
            ...(section.htmlTag === 'input' ? { value: section.text || '' } : {}),
            ...(section.htmlTag === 'img' 
              ? { 
                  src: section.src || '', 
                  width: section.width || undefined,
                  height: section.height || undefined
                } 
              : {}),
        },
        !voidElements.includes(section.htmlTag)
            ? [
                section.text,
                section.children && section.children.map((child: any) => renderSection(child)),
              ]
            : null
    );
  }

  return(
    <div>
      {renderSection(LinkFooter())}
    </div>
  )
}