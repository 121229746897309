import generateUniqueId from "../../../utils/generateId"
import DataProps from "../../ListFast/Components/DndKit/utils/DataProps"

const Newsletters: () => DataProps = () => {
    return {
      "id": generateUniqueId(),
      "index": 6,
      "htmlTag": "section",
      "children": [
        {
          "id": generateUniqueId(),
          "text": "Join Waitlist",
          "index": 0,
          "htmlTag": "h3",
          "children": [],
          "className": [
            "text-center",
            "text-4xl",
            "font-extrabold",
            "sm:text-5xl",
            "md:text-6xl",
          ]
        },
        {
          "id": generateUniqueId(),
          "index": 1,
          "htmlTag": "div",
          "children": [
            {
              "id": generateUniqueId(),
              "index": 0,
              "htmlTag": "input",
              "children": [],
              "className": [
                "flex",
                "h-12",
                "min-w-72",
                "rounded-md",
                "bg-transparent",
                "border",
                "border-input",
                "py-3"
              ]
            },
            {
              "id": generateUniqueId(),
              "text": "Join Waitlist",
              "index": 1,
              "htmlTag": "button",
              "children": [],
              "className": [
                "rounded-md",
                "py-2",
                "px-10",
                "text-primary-foreground",
                "bg-primary",
                "font-semibold",
                "hover:bg-opacity-80",
                "transition"
              ]
            }
          ],
          "className": [
            "flex",
            "gap-3",
            "justify-center",
            "pt-5"
          ]
        }
      ],
      "className": [
        "mx-auto",
        "px-4",
        "sm:px-6",
        "lg:px-8",
        "max-w-7xl",
        "text-center",
        "py-20",
        "flex",
        "flex",
        "flex-col",
        "text-foreground",
        "flex-col",
      ]
    }
}

export default Newsletters