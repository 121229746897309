import React, { useState, useEffect, useRef, useCallback, CSSProperties } from 'react';
import {
  DndContext,
  closestCenter,
  KeyboardSensor,
  MouseSensor,
  TouchSensor,
  useSensor,
  useSensors,
} from '@dnd-kit/core';
import {
  arrayMove,
  sortableKeyboardCoordinates,
} from '@dnd-kit/sortable';
import { Input } from '../../../components/input';
import DataProps from '../Components/DndKit/utils/DataProps';
import SideBar from '../Components/SideBar';
import { ChevronDown, ChevronRight, X } from 'lucide-react';
import { Label } from "../../../components/label"
import { Select, SelectContent, SelectItem, SelectTrigger, SelectValue } from "../../../components/select"
import { ScrollArea } from "../../../components/scroll-area"
import { Separator } from "../../../components/separator"
import PlaygroundTopBar from '../Components/TopBars/PlaygroundTopBar';
import { baseUrl } from '../../../baseUrl';
import initialData from '../../../utils/InitialData';
import { toPng } from 'html-to-image';
import { debounce } from "lodash"
import TailwindClasses from '../../../utils/TailwindClasses';
import { Command } from 'cmdk';
import { Button } from '../../../components/button';
import { Dialog, DialogContent, DialogTrigger } from '../../../components/dialog';
import { ChevronLeft } from 'lucide-react';
import { Textarea } from '../../../components/textarea';
import Header from "../../Templates/Headers/Header";
import HeroCentred from "../../Templates/Hero/HeroCentred";
import HeroWithImage from "../../Templates/Hero/HeroWithImage";
import KeyFeaturesSection from "../../Templates/Features/KeyFeaturesSection";
import FeaturesCentred2x2 from "../../Templates/Features/FeaturesCentred2x2";
import Newsletters from "../../Templates/Newsletters/Newsletters";
import LinkFooter from "../../Templates/Footers/LinkFooter";
import ColFooter from "../../Templates/Footers/4colFooter";
import RecursiveSortable from '../Components/DndKit/RecursiveSortable';
import Cookies from 'js-cookie';
import { Navigate } from 'react-router-dom';
import { themeMap, defaultTheme } from '../Components/Themes/Theme';
import { RadioGroup, RadioGroupItem } from '../../../components/radio-group';


export default function Playground() {
  const redBackgroundRef = useRef<HTMLDivElement | null>(null);
  const [font, setFont] = useState("Montserra");
  const [iaComponentPrompt, setIaComponentPrompt] = useState('');
  const componentRef = useRef<HTMLDivElement | null>(null);
  const [selectedItemText, setSelectedItemText] = useState<string>("");
  const [data, setData] = useState<DataProps[]>(initialData);
  const [selectedId, setSelectedId] = useState<string | null>(null);
  const [inputClass, setInputClass] = useState<string>('');
  const [filtredClasses, setFiltredClasses] = useState<string[]>([]);
  const [scale, setScale] = useState(0.8);
  const [translateY, setTranslateY] = useState(-50);
  const [dialogNewSection, setDialogNewSection] = useState(false);
  type ThemeKeys = keyof typeof sectionMap;
  const [selectedTheme, setSelectedTheme] = useState<ThemeKeys | null>(null);
  const [selectedComponent, setSelectedComponent] = useState<DataProps | null>(null);
  const [isOpenSelectTheme, setIsOpenSelectTheme] = useState(false);
  const [selectedOption, setSelectedOption] = useState('default');
  const [theme, setTheme] = useState<CSSProperties>(defaultTheme);
  const buttonRef = useRef<HTMLButtonElement | null>(null);
  const divRef = useRef<HTMLDivElement | null>(null);

  const handleClickOutside = (event: MouseEvent) => {
    if (divRef.current && !divRef.current.contains(event.target as Node) &&
        buttonRef.current && !buttonRef.current.contains(event.target as Node)) {
      setIsOpenSelectTheme(false);
    }
  };

  const handleChangeTheme = (themeName: string) => {
    const theme = themeMap[themeName];
    setTheme(theme)
    if (theme) {
      localStorage.setItem('selectedTheme', themeName);
    }
  }

  const loadThemeFromLocalStorage = () => {
    const savedThemeName = localStorage.getItem('selectedTheme') || "default";
    console.log(themeMap[savedThemeName])
    if (savedThemeName && themeMap[savedThemeName]) {
      setTheme(themeMap[savedThemeName]);
    }
    else {
      setTheme(defaultTheme);
    }
  };

  useEffect(() => {
    loadThemeFromLocalStorage()
  }, []);

  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  const toggleDiv = () => setIsOpenSelectTheme(!isOpenSelectTheme);

  const sectionMap = {
    headers: [{ name: "Header", component: Header(), image: "/header.png" }],
    heros: [
      { name: "Hero Centred", component: HeroCentred(), image: "/HeroCenter.png" },
      { name: "Hero With Image", component: HeroWithImage(), image: "/HeroWithImage.png" },
    ],
    features: [
      { name: "Key Features", component: KeyFeaturesSection(), image: "/KeyFeaturesSection.png" },
      { name: "Features 2x2", component: FeaturesCentred2x2(), image: "/FeatureCentred2x2.png" },
    ],
    newsletters: [{ name: "Newsletter", component: Newsletters(), image: "/Newsletters.png" }],
    footers: [
      { name: "Link Footer", component: LinkFooter(), image: "/LinkFooter.png" },
      { name: "4 Column Footer", component: ColFooter(), image: "/ColFooter.png" },
    ],
  };

  const handleSelectTheme = (theme: ThemeKeys) => {
    setSelectedTheme(theme);
    setSelectedComponent(null);
  };

  const getProjectData = () => {
      fetch(baseUrl + '/get_project_from_token', { 
          headers: {
              "tokenUser": Cookies.get("userToken") || "",
              "projectToken": localStorage.getItem("projectToken") || ""
          } 
      })
      .then(response => response.json())
      .then(resData => {
        console.log(resData)
        try {
            setData(resData[0]["data"])
            setFont(resData[0]["font"])
          } catch {
            console.log("errorrrr")
          }
      })
  };

  const updateProject = (data: DataProps[], theme: string) => {
    fetch(baseUrl + '/update_project_from_token', { 
      method: 'POST',
      headers: {
        "Content-Type": "application/json",
        "tokenUser": Cookies.get("userToken") || "",
        "projectToken": localStorage.getItem("projectToken") || ""
      },
      body: JSON.stringify({"data" : data, "theme" : theme})
    })
    .then(response => response.json())
    .catch(error => console.error('Error updating project:', error));
  };

  const uploadImage = async () => {
    if (!componentRef.current) return;
    try {
      const dataUrl = await toPng(componentRef.current);
      const response = await fetch(dataUrl);
      const blob = await response.blob();

      const headers = {
        'tokenUser': Cookies.get("userToken") || "",
        'projectToken': localStorage.getItem("projectToken") || "",
        'Content-Type': 'application/octet-stream'
      };

      const uploadResponse = await fetch(baseUrl + '/save_or_update_preview', {
        method: 'POST',
        headers: headers,
        body: blob
      });

      const result = await uploadResponse.text();
      console.log("result", result)
    } catch (error) {
      console.error('Erreur lors de l\'upload de l\'image :', error);
    }
  };

  useEffect(() => {
    if (window.location.pathname.startsWith('/playground')) {
      document.body.classList.add('no-scroll');
    }      
  }, [])

  useEffect(() => {
    const fetchDataAndUploadImage = async () => {
      await getProjectData();
      setTimeout(() => {
        uploadImage();
      }, 1000);
    };

    fetchDataAndUploadImage();
  }, []);

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      const selectedOutline = document.getElementById('selected-element');
      const hoveredOutline = document.getElementById('hovered-element');

      if (redBackgroundRef.current) {
        const clickedInsideRedBackground = redBackgroundRef.current.contains(event.target as Node);
        const clickedOnComponent = componentRef.current && componentRef.current.contains(event.target as Node);

        if (clickedInsideRedBackground && !clickedOnComponent) {
          setSelectedId(null);

          if (selectedOutline && !selectedOutline.contains(event.target as Node)) {
            selectedOutline.style.display = 'none';
          }
          if (hoveredOutline && !hoveredOutline.contains(event.target as Node)) {
            hoveredOutline.style.display = 'none';
          }
        }
      } else {
        console.error('Red background ref is not initialized.');
      }
    };

    document.addEventListener('mousedown', handleClickOutside);

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  const handleChangeFont = (fontName: string) => {
    setFont(fontName)
    updateProject(data, localStorage.getItem("selectedTheme") || "default")
  }

  const sensors = useSensors(
      useSensor(MouseSensor, {
          activationConstraint: {
              delay: 200,
              tolerance: 5,
          },
      }),
      useSensor(TouchSensor, {
          activationConstraint: {
              delay: 200,
              tolerance: 5,
          },
      }),
      useSensor(KeyboardSensor, {
          coordinateGetter: sortableKeyboardCoordinates,
      })
  );

  const findAndUpdate = (nodes: DataProps[], id: string, newChildren: DataProps[]): DataProps[] => {
    return nodes.map(node => {
      if (node.id === id) {
        return { ...node, children: newChildren };
      }
      if (node.children.length > 0) {
        return { ...node, children: findAndUpdate(node.children, id, newChildren) };
      }
      return node;
    });
  };
  
  useEffect(() => {
    if (selectedId) {
      const rect = document.getElementById(selectedId)?.getBoundingClientRect();
      const outline = document.getElementById('selected-element');
      if (outline && rect) {
        outline.style.top = `${rect.top + window.scrollY}px`;
        outline.style.left = `${rect.left + window.scrollX}px`;
        outline.style.width = `${rect.width}px`;
        outline.style.height = `${rect.height}px`;
        outline.style.display = 'block';
      }
    }
  }, [data, selectedId]);

  const handleDragEnd = (event: any, parentId: string | null) => {
    const outline = document.getElementById('selected-element');
        
    if (outline) {
        outline.style.display = 'block';
    }
    const { active, over } = event;
    if (!over) return;

    if (active.id !== over.id) {
      setData((prevData) => {
        const updatedData = updateDataAfterMove(prevData, active.id, over.id, parentId);
        updateProject(updatedData, localStorage.getItem("selectedTheme") || "default")
        setTimeout(() => uploadImage(), 300)
        return updatedData;
      });
    }
  };

  function updateDataAfterMove(data: DataProps[], activeId: string, overId: string, parentId: string | null) : DataProps[] {
    const moveItems = (items: DataProps[], activeId: string, overId: string): DataProps[] => {
      const oldIndex = items.findIndex((item) => item.id === activeId);
      const newIndex = items.findIndex((item) => item.id === overId);
      if (oldIndex === -1 || newIndex === -1) return items;
      return arrayMove(items, oldIndex, newIndex);
    };

    if (!parentId) {
      return moveItems(data, activeId, overId);
    }

    return data.map((item) => {
      if (item.id === parentId) {
        return {
          ...item,
          children: moveItems(item.children, activeId, overId),
        };
      } else if (item.children.length > 0) {
        return {
          ...item,
          children: updateDataAfterMove(item.children, activeId, overId, parentId),
        };
      }
      return item;
    });
  }

  const updateIndexes = (nodes: DataProps[]): DataProps[] => {
    return nodes.map((node, index) => ({
      ...node,
      index,
      children: updateIndexes(node.children)
    }));
  };
  
  const handleClick = (id: string) => {
    if (id) {
      setSelectedId(id);
      const selectedItem = id ? findItemById(data, id) : null;
      setSelectedItemText(selectedItem?.text || "")
    } else {
      console.error("ID is null or undefined");
    }
  };
  

  const findItemById = (nodes: DataProps[], id: string): DataProps | undefined => {
    const findInChildren = (nodes: DataProps[]): DataProps | undefined => {
      for (const node of nodes) {
        if (node.id === id) return node;
        if (node.children.length > 0) {
          const result = findInChildren(node.children);
          if (result) return result;
        }
      }
      return undefined;
    };
    return findInChildren(nodes);
  };
  
  const selectedItem = selectedId ? findItemById(data, selectedId) : null;

  const classNames = selectedItem ? selectedItem.className : [];

  const findAndUpdateClass = (nodes: DataProps[], id: string, className: string, add: boolean): DataProps[] => {
    return nodes.map(node => {
      if (node.id === id) {
        const updatedClassNames = add
          ? [...node.className, className]
          : node.className.filter(cls => cls !== className);
        return { ...node, className: updatedClassNames };
      }
      if (node.children.length > 0) {
        return { ...node, children: findAndUpdateClass(node.children, id, className, add) };
      }
      return node;
    });
  };
  
  const handleClassRemove = (item: string) => {
    const outline = document.getElementById('selected-element');
    if (outline) {
      outline.style.display = 'none';
    }
    if (selectedId) {
      setData(prevData => {
        const updatedData = findAndUpdateClass(prevData, selectedId, item, false);
        updateProject(updatedData, localStorage.getItem("selectedTheme") || "default")
        return updatedData;
      });
    }
  };
  
  const handleAddClass = (item: string) => {
    const outline = document.getElementById('selected-element');
    if (outline) {
      outline.style.display = 'none';
    }
    if (selectedId && inputClass.trim()) {
      setData(prevData => {
        const updatedData = findAndUpdateClass(prevData, selectedId, item, true);
        updateProject(updatedData, localStorage.getItem("selectedTheme") || "default")
        return updatedData;
      });
      setInputClass('');
    }
  };

  const handleInputChange = (value: string) => {
    setInputClass(value);

    if (value !== "") {
      const newFilteredClasses = TailwindClasses.filter((cls) =>
        cls.startsWith(value)
      )
      setFiltredClasses([...newFilteredClasses, value]);
    } else {
      setFiltredClasses([]);
    }
  };


  const handleWheel = (e:any) => {
    const selectedOutline = document.getElementById('selected-element');
    const hoveredOutline = document.getElementById('hovered-element');
  
    if (selectedOutline) {
      selectedOutline.style.display = 'none';
    }
    if (hoveredOutline) {
      hoveredOutline.style.display = 'none';
    }

    if (e.ctrlKey) {
      const newScale = scale + e.deltaY * -0.001;
      setScale(Math.max(0.2, Math.min(newScale, 3)));
    } else {
      setTranslateY(prevY => prevY - e.deltaY * 1.5);
    }
  };

  useEffect(() => {
    const preventBrowserZoom = (e:any) => {
      if (e.ctrlKey) {
        e.preventDefault();
      }
    };

    window.addEventListener('wheel', preventBrowserZoom, { passive: false });

    return () => {
      window.removeEventListener('wheel', preventBrowserZoom);
    };
  }, []);

  const handleChangeComponentPrompt = (event: any) => {
    setIaComponentPrompt(event.target.value);
  };

  const handleKeyDownIa = (event: any) => {
    if (event.key === 'Enter') {
      setIaComponentPrompt("")
      fetch(baseUrl + '/create_component_with_api', { 
        headers: {
            "prompt": iaComponentPrompt
        } 
      })
      .then(response => response.json())
      .then(resData => {
        if (selectedId) {
          setData(prevData => {
            const updatedData = handleChangeClasses(prevData, selectedId, resData["success"]["classes"]);
            const updatedDataHtml = handleChangeHtmlTag(updatedData, selectedId, resData["success"]["htmlTag"]);
            updateProject(updatedDataHtml, localStorage.getItem("selectedTheme") || "default")
            return updatedDataHtml;
          })
        }
      })
    }
  };

  const handleKeyDownText = (event: any) => {
    if (event.key === 'Enter') {
    }
  };

  const removeItemById = (data: DataProps[], idToRemove: string): DataProps[] => {
    const removeFromChildren = (items: DataProps[], id: string): DataProps[] => {
      return items
        .map(item => {
          item.children = removeFromChildren(item.children, id);
          return item.id === id ? null : item;
        })
        .filter(item => item !== null) as DataProps[];
    };
  
    return removeFromChildren(data, idToRemove);
  };


  const handleDeleteItem = (item: string) => {
    const updatedData = removeItemById(data, item);
    setData(updatedData)
    const outline = document.getElementById('selected-element');
    if (outline) {
      outline.style.display = 'none';
    }
    setSelectedId("")
    updateProject(updatedData, localStorage.getItem("selectedTheme") || "default")
  }

  const handleChangeClasses = (nodes: DataProps[], id: string, classes: string): DataProps[] => {
    const newClasses = classes.split(' ');
  
    return nodes.map(node => {
      if (node.id === id) {
        return { ...node, className: newClasses };
      }
      if (node.children.length > 0) {
        return { ...node, children: handleChangeClasses(node.children, id, classes) };
      }
      return node;
    });
  };

  const handleChangeHtmlTag = (nodes: DataProps[], id: string, htmlTag: string): DataProps[] => {
    const updateNode = (nodes: DataProps[]): DataProps[] => {
      return nodes.map(node => {
        if (node.id === id) {
          return { ...node, htmlTag };
        }
        if (node.children && node.children.length > 0) {
          return { ...node, children: updateNode(node.children) };
        }
        return node;
      });
    };
  
    return updateNode(nodes);
  };

  const AddSection = (nodes: DataProps[], component: DataProps): DataProps[] => {
    if (nodes[0].htmlTag === "div") {
      component.index = nodes[0].children.length
      nodes[0].children.push(component)
    }
    return nodes;
  };

  const handleAddSection = (item: DataProps) => {
    console.log(item)
    setSelectedTheme(null)
    const updatedNodes = AddSection(data, item);
    setData(updatedNodes)
    setDialogNewSection(false)
    updateProject(updatedNodes, localStorage.getItem("selectedTheme") || "default")
  }

  const handleChangeText = (event: any) => {
    const outline = document.getElementById('selected-element');

    if (outline) {
      outline.style.display = 'none';
    }

    if (selectedItem) {
      selectedItem.text = event.target.value
    }
    setSelectedItemText(event.target.value);
    updateProject(data, localStorage.getItem("selectedTheme") || "default")
  };
  

  const token = Cookies.get('userToken');
  const url = baseUrl + '/check_cookies'; 
  const [isAuthenticated, setIsAuthenticated] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(true);

  useEffect(() => {
      const checkAuth = async () => {
          const data = { credentials: token };

          try {
              const response = await fetch(url, {
                  method: 'POST',
                  headers: {
                      'Content-Type': 'application/json',
                  },
                  body: JSON.stringify(data),
              });

              if (response.ok) {
                  setIsAuthenticated(true);
              } else {
                  setIsAuthenticated(false);
              }
          } catch (error) {
              setIsAuthenticated(false);
          } finally {
              setIsLoading(false);
          }
      };

      checkAuth();
  }, [token, url]);

  if (isLoading) {
      return <div></div>;
  }
  
  return isAuthenticated ? (
    <>
      <div id="hovered-element" className="pointer-events-none absolute z-50 hidden border-2 border-blue-500"></div>
      <div id="selected-element" className="pointer-events-none absolute z-50 hidden border-2 border-blue-500"></div>
      <div>
        <PlaygroundTopBar />
        <div className='flex'>
        <div className={`border-r p-2 w-[22%] bg-background`}>
            {selectedId ? (
                <>
                    <div className={`h-full bg-background`}>
                    <ScrollArea className="h-full w-full">
                        <div className="p-3">
                        <h2 className="text-xl font-semibold mb-4">{selectedItem?.htmlTag}</h2>
                        {selectedItem?.children.length == 0 && (
                          <>
                            <Separator className="my-4" />
                              
                            <section className="mb-6">
                                <div className="space-y-2">
                                    <Label htmlFor="site-description">AI Component Creator</Label>
                                    <Input 
                                      id="site-description" 
                                      placeholder='A green button with yellow border'
                                      value={iaComponentPrompt}
                                      onKeyDown={handleKeyDownIa}
                                      onChange={handleChangeComponentPrompt}
                                    />
                                </div>
                            </section>
                          </>
                        )}
                          
                        {selectedItem?.text && (
                        <>
                          <Separator className="my-4" />
                          <section className="mb-6">
                              <div className="space-y-2">
                                  <Label htmlFor="site-description">Text</Label>
                                  <Textarea 
                                    id="site-description"  
                                    value={selectedItemText}
                                    onKeyDown={handleKeyDownText}
                                    onChange={handleChangeText}
                                  />
                              </div>
                          </section>
                        </> 
                        )}
                        
                        <Separator className="my-4" />
                        <section className="mb-6">
                            <h3 className="text-sm font-medium mb-2">Tailwind Classes</h3>
                            <div className="space-y-4">
                            <div className="mb-4">
                                <Command>
                                    <Command.Input
                                        value={inputClass} 
                                        onValueChange={handleInputChange}
                                        placeholder="Enter Tailwind class"
                                        className="flex h-10 w-full rounded-md border border-input bg-background px-3 py-2 text-sm"
                                    />
                                    <ScrollArea className={`h-72 rounded-md ${inputClass === "" ? "hidden" : "block"}`}>
                                      <Command.List>
                                        {filtredClasses.map((item, key) => (
                                          <Command.Item className='cursor-pointer hover:bg-blue-500 px-4 py-2 rounded-xl' key={key} onSelect={() => handleAddClass(item)}>{item}</Command.Item>
                                        ))}
                                      </Command.List>
                                    </ScrollArea>
                                </Command>
                            </div>
                            <div className="mt-4 flex flex-wrap gap-3">
                                {classNames.map((item, index) => (
                                    <div key={index} className="transition-all duration-300 flex items-center rounded-md border border-input bg-background px-3 py-1">
                                        <p>{item}</p>

                                        <button
                                            onClick={() => handleClassRemove(item)}
                                            className="transition-all duration-300 text-black-500 p-[1px] ml-2 hover:bg-red-500 hover:text-white rounded-md border border-input"
                                        >
                                            <X size={20}/>
                                        </button>
                                    </div>
                                ))}
                            </div>
                            </div>
                        </section>
                        <Separator className="my-4" />
                        <button onClick={() => handleDeleteItem(selectedId)} className="h-10 w-full py-2 bg-red-500 text-white hover:bg-red-500/90 inline-flex items-center justify-center whitespace-nowrap rounded-md text-sm font-medium ring-offset-background transition-bgColors focus:outline-none focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-ring focus-visible:ring-offset-2 disabled:pointer-events-none disabled:opacity-50">Delete Item</button>
                        </div>
                    </ScrollArea>
                    </div>
                
                </>
            ) : (
              <div className={`h-full bg-background`}>
              <ScrollArea className="h-full w-full">
                <div className="p-3">
                  <h2 className="text-xl font-semibold mb-4">General Information</h2>
                  {/* <Separator className="my-4" />
                  
                  <section className="mb-6">
                    <div className="space-y-2">
                        <Label htmlFor="site-description">AI Theme and bgColor Creator</Label>
                        <Input id="site-description" placeholder="A yellow dark theme" />
                    </div>
                  </section> */}
                  
                  <Separator className="my-4" />
                  
                  <section className="mb-6">
                    <h3 className="text-sm font-medium mb-2">Colors</h3>
                    <div className="space-y-4">
                    <button onClick={toggleDiv} ref={buttonRef} className="h-10 w-full py-2 bg-primary text-primary-foreground hover:bg-primary/90 inline-flex items-center justify-center whitespace-nowrap rounded-md text-sm font-medium ring-offset-background transition-bgColors focus:outline-none focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-ring focus-visible:ring-offset-2 disabled:pointer-events-none disabled:opacity-50">
                      Theme
                    </button>
                    {isOpenSelectTheme && (
                      <div ref={divRef} className="absolute mt-1 z-50 overflow-hidden rounded-md border bg-popover px-1 py-3 text-sm text-popover-foreground shadow-md animate-in fade-in-0 zoom-in-95">
                          <RadioGroup defaultValue={localStorage.getItem('selectedTheme') || "default"}>
                              <div className="flex items-center space-x-2 justify-between w-[280px] transition duration-200 hover:bg-border p-1 rounded-md">
                                  <label htmlFor="r1" className="flex items-center space-x-2 justify-between w-full cursor-pointer" onClick={() => handleChangeTheme("default")}>
                                      <div className="flex items-center space-x-2">
                                          <RadioGroupItem value="default" id="r1" />
                                          <Label htmlFor="r1">Default</Label>
                                      </div>
                                      <div className='flex items-center overflow-hidden h-5 w-24 rounded border border-gs-gray-alpha-400'>
                                          <div className="relative h-full flex-1" style={{ "--color": "hsla(240, 4.8%, 95.9%)", backgroundColor: "var(--color)" } as React.CSSProperties}></div>
                                          <div className="relative h-full flex-1" style={{ "--color": "hsla(240 5.9% 90%)", backgroundColor: "var(--color)" } as React.CSSProperties}></div>
                                          <div className="relative h-full flex-1" style={{ "--color": "hsla(240 5.9% 10%)", backgroundColor: "var(--color)" } as React.CSSProperties}></div>
                                          <div className="relative h-full flex-1" style={{ "--color": "hsla(240 10% 3.9%)", backgroundColor: "var(--color)" } as React.CSSProperties}></div>
                                      </div>
                                  </label>
                              </div>

                              <div className="flex items-center space-x-2 justify-between w-[280px] transition duration-200 hover:bg-border p-1 rounded-md">
                                  <label htmlFor="r2" className="flex items-center space-x-2 justify-between w-full cursor-pointer" onClick={() => handleChangeTheme("ruby")}>
                                      <div className="flex items-center space-x-2">
                                          <RadioGroupItem value="ruby" id="r2" />
                                          <Label htmlFor="r2">Ruby</Label>
                                      </div>
                                      <div className='flex items-center overflow-hidden h-5 w-24 rounded border border-gs-gray-alpha-400'>
                                          <div className="relative h-full flex-1" style={{ "--color": "hsla(240, 4.8%, 95.9%)", backgroundColor: "var(--color)" } as React.CSSProperties}></div>
                                          <div className="relative h-full flex-1" style={{ "--color": "hsla(240 5.9% 90%)", backgroundColor: "var(--color)" } as React.CSSProperties}></div>
                                          <div className="relative h-full flex-1" style={{ "--color": "hsla(346.8 77.2% 49.8%)", backgroundColor: "var(--color)" } as React.CSSProperties}></div>
                                          <div className="relative h-full flex-1" style={{ "--color": "hsla(240 10% 3.9%)", backgroundColor: "var(--color)" } as React.CSSProperties}></div>
                                      </div>
                                  </label>
                              </div>

                              <div className="flex items-center space-x-2 justify-between w-[280px] transition duration-200 hover:bg-border p-1 rounded-md">
                                  <label htmlFor="r3" className="flex items-center space-x-2 justify-between w-full cursor-pointer" onClick={() => handleChangeTheme("sapphire")}>
                                      <div className="flex items-center space-x-2">
                                          <RadioGroupItem value="sapphire" id="r3" />
                                          <Label htmlFor="r3">Sapphire</Label>
                                      </div>
                                      <div className='flex items-center overflow-hidden h-5 w-24 rounded border border-gs-gray-alpha-400'>
                                          <div className="relative h-full flex-1" style={{ "--color": "hsla(240, 4.8%, 95.9%)", backgroundColor: "var(--color)" } as React.CSSProperties}></div>
                                          <div className="relative h-full flex-1" style={{ "--color": "hsla(240 5.9% 90%)", backgroundColor: "var(--color)" } as React.CSSProperties}></div>
                                          <div className="relative h-full flex-1" style={{ "--color": "hsla(221.2 83.2% 53.3%)", backgroundColor: "var(--color)" } as React.CSSProperties}></div>
                                          <div className="relative h-full flex-1" style={{ "--color": "hsla(240 10% 3.9%)", backgroundColor: "var(--color)" } as React.CSSProperties}></div>
                                      </div>
                                  </label>
                              </div>
                              <div className="flex items-center space-x-2 justify-between w-[280px] transition duration-200 hover:bg-border p-1 rounded-md">
                                  <label htmlFor="r4" className="flex items-center space-x-2 justify-between w-full cursor-pointer" onClick={() => handleChangeTheme("emerauld")}>
                                      <div className="flex items-center space-x-2">
                                          <RadioGroupItem value="emerald" id="r4" />
                                          <Label htmlFor="r4">Emerald</Label>
                                      </div>
                                      <div className='flex items-center overflow-hidden h-5 w-24 rounded border border-gs-gray-alpha-400'>
                                          <div className="relative h-full flex-1" style={{ "--color": "hsla(240, 4.8%, 95.9%)", backgroundColor: "var(--color)" } as React.CSSProperties}></div>
                                          <div className="relative h-full flex-1" style={{ "--color": "hsla(240 5.9% 90%)", backgroundColor: "var(--color)" } as React.CSSProperties}></div>
                                          <div className="relative h-full flex-1" style={{ "--color": "hsla(142 86% 28%)", backgroundColor: "var(--color)" } as React.CSSProperties}></div>
                                          <div className="relative h-full flex-1" style={{ "--color": "hsla(240 10% 3.9%)", backgroundColor: "var(--color)" } as React.CSSProperties}></div>
                                      </div>
                                  </label>
                              </div>
                              <div className="flex items-center space-x-2 justify-between w-[280px] transition duration-200 hover:bg-border p-1 rounded-md">    
                                  <label htmlFor="r5" className="flex items-center space-x-2 justify-between w-full cursor-pointer" onClick={() => handleChangeTheme("windows")}>
                                      <div className="flex items-center space-x-2">
                                          <RadioGroupItem value="windows" id="r5" />
                                          <Label htmlFor="r5">Windows 98</Label>
                                      </div>
                                      <div className='flex items-center overflow-hidden h-5 w-24 rounded border border-gs-gray-alpha-400'>
                                          <div className="relative h-full flex-1" style={{ "--color": "hsla(240 100% 50%)", backgroundColor: "var(--color)" } as React.CSSProperties}></div>
                                          <div className="relative h-full flex-1" style={{ "--color": "hsla(0 0% 60%)", backgroundColor: "var(--color)" } as React.CSSProperties}></div>
                                          <div className="relative h-full flex-1" style={{ "--color": "hsla(0 0% 80%)", backgroundColor: "var(--color)" } as React.CSSProperties}></div>
                                          <div className="relative h-full flex-1" style={{ "--color": "hsla(240 10% 3.9%)", backgroundColor: "var(--color)" } as React.CSSProperties}></div>
                                      </div>
                                  </label>
                              </div>
                              <div className="flex items-center space-x-2 justify-between w-[280px] transition duration-200 hover:bg-border p-1 rounded-md">
                                  <label htmlFor="r6" className="flex items-center space-x-2 justify-between w-full cursor-pointer" onClick={() => handleChangeTheme("daylight")}>
                                      <div className="flex items-center space-x-2">
                                          <RadioGroupItem value="daylight" id="r6" />
                                          <Label htmlFor="r6">Daylight</Label>
                                      </div>
                                      <div className='flex items-center overflow-hidden h-5 w-24 rounded border border-gs-gray-alpha-400'>
                                          <div className="relative h-full flex-1" style={{ "--color": "hsla(36 64% 57%)", backgroundColor: "var(--color)" } as React.CSSProperties}></div>
                                          <div className="relative h-full flex-1" style={{ "--color": "hsla(36 45% 60%)", backgroundColor: "var(--color)" } as React.CSSProperties}></div>
                                          <div className="relative h-full flex-1" style={{ "--color": "hsla(36 45% 70%)", backgroundColor: "var(--color)" } as React.CSSProperties}></div>
                                          <div className="relative h-full flex-1" style={{ "--color": "hsla(36 45% 15%)", backgroundColor: "var(--color)" } as React.CSSProperties}></div>
                                      </div>
                                  </label>
                              </div>
                              <div className="flex items-center space-x-2 justify-between w-[280px] transition duration-200 hover:bg-border p-1 rounded-md">
                                  <label htmlFor="r7" className="flex items-center space-x-2 justify-between w-full cursor-pointer" onClick={() => handleChangeTheme("midnight")}>
                                      <div className="flex items-center space-x-2">
                                          <RadioGroupItem value="midnight" id="r7" />
                                          <Label htmlFor="r7">Midnight</Label>
                                      </div>
                                      <div className='flex items-center overflow-hidden h-5 w-24 rounded border border-gs-gray-alpha-400'>
                                          <div className="relative h-full flex-1" style={{ "--color": "hsla(240 0% 13%)", backgroundColor: "var(--color)" } as React.CSSProperties}></div>
                                          <div className="relative h-full flex-1" style={{ "--color": "hsla(240 6% 20%)", backgroundColor: "var(--color)" } as React.CSSProperties}></div>
                                          <div className="relative h-full flex-1" style={{ "--color": "hsla(240 0% 90%)", backgroundColor: "var(--color)" } as React.CSSProperties}></div>
                                          <div className="relative h-full flex-1" style={{ "--color": "hsla(60 5% 90%", backgroundColor: "var(--color)" } as React.CSSProperties}></div>
                                      </div>
                                  </label>
                              </div>
                              <div className="flex items-center space-x-2 justify-between w-[280px] transition duration-200 hover:bg-border p-1 rounded-md">
                                  <label htmlFor="r8" className="flex items-center space-x-2 justify-between w-full cursor-pointer" onClick={() => handleChangeTheme("pastel")}>
                                      <div className="flex items-center space-x-2">
                                          <RadioGroupItem value="pastel" id="r8" />
                                          <Label htmlFor="r8">Pastel</Label>
                                      </div>
                                      <div className='flex items-center overflow-hidden h-5 w-24 rounded border border-gs-gray-alpha-400'>
                                          <div className="relative h-full flex-1" style={{ "--color": "hsla(211 86% 70%)", backgroundColor: "var(--color)" } as React.CSSProperties}></div>
                                          <div className="relative h-full flex-1" style={{ "--color": "hsla(210 40% 80%)", backgroundColor: "var(--color)" } as React.CSSProperties}></div>
                                          <div className="relative h-full flex-1" style={{ "--color": "hsla(308 56% 85%)", backgroundColor: "var(--color)" } as React.CSSProperties}></div>
                                          <div className="relative h-full flex-1" style={{ "--color": "hsla(339 20% 20%)", backgroundColor: "var(--color)" } as React.CSSProperties}></div>
                                      </div>
                                  </label>
                              </div>
                              <div className="flex items-center space-x-2 justify-between w-[280px] transition duration-200 hover:bg-border p-1 rounded-md">
                                  <label htmlFor="r9" className="flex items-center space-x-2 justify-between w-full cursor-pointer" onClick={() => handleChangeTheme("deepsea")}>
                                      <div className="flex items-center space-x-2">
                                          <RadioGroupItem value="deepsea" id="r9" />
                                          <Label htmlFor="r9">Deep Sea</Label>
                                      </div>
                                      <div className='flex items-center overflow-hidden h-5 w-24 rounded border border-gs-gray-alpha-400'>
                                          <div className="relative h-full flex-1" style={{ "--color": "hsla(198 70% 50%)", backgroundColor: "var(--color)" } as React.CSSProperties}></div>
                                          <div className="relative h-full flex-1" style={{ "--color": "hsla(210 50% 40%)", backgroundColor: "var(--color)" } as React.CSSProperties}></div>
                                          <div className="relative h-full flex-1" style={{ "--color": "hsla(200 100% 28%)", backgroundColor: "var(--color)" } as React.CSSProperties}></div>
                                          <div className="relative h-full flex-1" style={{ "--color": "hsla(180 100% 90%)", backgroundColor: "var(--color)" } as React.CSSProperties}></div>
                                      </div>
                                  </label>
                              </div>
                                  
                          </RadioGroup>
                      </div>
                    )}
                    </div>
                    {/* <div className="space-y-4">
                      <div className="space-y-2">
                        <Label htmlFor="background-bgColor">Primary Color</Label>
                        <Input 
                        type="color" 
                        id="Dominant-color" 
                        className="h-10 w-full" 
                        value={dominantColor}
                        onChange={handleDominantbgColorChange}/>
                      </div>
                    </div> */}
                  </section>
                  
                  <Separator className="my-4" />
                  <Dialog open={dialogNewSection} onOpenChange={setDialogNewSection}>
                    <DialogTrigger className='w-full'>
                      <button onClick={() => setDialogNewSection(true)} className="h-10 w-full py-2 bg-blue-500 text-white hover:bg-blue-500/90 inline-flex items-center justify-center whitespace-nowrap rounded-md text-sm font-medium ring-offset-background transition-bgColors focus:outline-none focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-ring focus-visible:ring-offset-2 disabled:pointer-events-none disabled:opacity-50">Add Section</button>
                    </DialogTrigger>
                    <DialogContent className='w-[70%]'>
                      <ScrollArea className='h-[80vh]'>
                        <div className="w-full">
                            {!selectedTheme ? (
                              <div className="grid w-full grid-cols-1 gap-8 sm:grid-cols-[repeat(auto-fill,minmax(250px,1fr))]">
                                <div className="group" onClick={() => handleSelectTheme("headers")}>
                                  <div className="relative flex h-[200px] w-full items-center justify-center overflow-hidden rounded-md bg-white transition group-hover:border-blue-700 group-hover:border-2">
                                    <img src="/header.png" alt="Headers Image" />
                                  </div>
                                  <div className="mt-4 flex items-center justify-between">
                                    <p className="text-lg font-semibold">Headers</p>
                                    <p className="text-md text-complementary">1 component</p>
                                  </div>
                                </div>
                                <div className="group" onClick={() => handleSelectTheme("heros")}>
                                  <div className="relative flex h-[200px] w-full items-center justify-center overflow-hidden rounded-md bg-white transition group-hover:border-blue-700 group-hover:border-2">
                                    <img src="/hero.png" alt="Heros Image" />
                                  </div>
                                  <div className="mt-4 flex items-center justify-between">
                                    <p className="text-lg font-semibold">Heros</p>
                                    <p className="text-md text-complementary">2 component</p>
                                  </div>
                                </div>
                                <div className="group" onClick={() => handleSelectTheme("features")}>
                                  <div className="relative flex h-[200px] w-full items-center justify-center overflow-hidden rounded-md bg-white transition group-hover:border-blue-700 group-hover:border-2">
                                    <img src="/features.png" alt="Features Image" />
                                  </div>
                                  <div className="mt-4 flex items-center justify-between">
                                    <p className="text-lg font-semibold">Features</p>
                                    <p className="text-md text-complementary">2 component</p>
                                  </div>
                                </div>
                                <div className="group" onClick={() => handleSelectTheme("newsletters")}>
                                  <div className="relative flex h-[200px] w-full items-center justify-center overflow-hidden rounded-md bg-white transition group-hover:border-blue-700 group-hover:border-2">
                                    <img src="/newsletters.png" alt="Newsletters Image" />
                                  </div>
                                  <div className="mt-4 flex items-center justify-between">
                                    <p className="text-lg font-semibold">Newsletters</p>
                                    <p className="text-md text-complementary">1 component</p>
                                  </div>
                                </div>
                                <div className="group" onClick={() => handleSelectTheme("footers")}>
                                  <div className="relative flex h-[200px] w-full items-center justify-center overflow-hidden rounded-md bg-white transition group-hover:border-blue-700 group-hover:border-2">
                                    <img src="/footer.png" alt="Footers Image" />
                                  </div>
                                  <div className="mt-4 flex items-center justify-between">
                                    <p className="text-lg font-semibold">Footers</p>
                                    <p className="text-md text-complementary">2 component</p>
                                  </div>
                                </div>
                              </div>
                            ) : (
                              <div>
                                <button onClick={() => setSelectedTheme(null)} className="my-4 bg-blue-500 text-white p-2 rounded">
                                  <ChevronLeft />
                                </button>
                                <div className="grid w-full grid-cols-1 gap-8 sm:grid-cols-[repeat(auto-fill,minmax(250px,1fr))]"> 
                                  {sectionMap[selectedTheme]?.map((item, index) => {
                                    return (
                                      <div className='group' onClick={() => handleAddSection(item.component)}>
                                        <div className="relative flex h-[200px] w-full items-center justify-center overflow-hidden rounded-md bg-white transition group-hover:border-blue-700 group-hover:border-2">
                                          <img src={item.image} alt={item.name} />
                                        </div>
                                          <div className="mt-4 flex items-center justify-between">
                                          <p className="text-lg font-semibold">{item.name}</p>
                                        </div>
                                      </div>
                                    )
                                  })}
                                  </div>
                                
                              </div>
                            )}
                            <div className="mt-8">
                              {selectedComponent ? (
                                <div className="p-4 border rounded-md">
                                  <h2 className="text-xl font-semibold mb-4">Selected Component:</h2>
                                  
                                </div>
                              ) : (
                                selectedTheme && <p className="text-gray-600">Click on a component to display it.</p>
                              )}
                            </div>
                        </div>
                      </ScrollArea>
                    </DialogContent>
                  </Dialog>
                </div>
              </ScrollArea>
            </div>
            )}
            </div>
            <div className={`w-[78%] overflow-hidden h-[100vh] bg-gray-200`}
                ref={redBackgroundRef}   
                onWheel={handleWheel}>
                <div
                    style={{
                        transform: `scale(${scale}) translateY(${translateY}px)`,
                        transition: 'transform 0.1s ease-in-out',
                    }}
                >
                    <div 
                    ref={componentRef}
                    className="min-h-[100vh] p-5 z-0 playground bg-background"
                    style={theme}
                    >
                        <DndContext
                          sensors={sensors}
                          collisionDetection={closestCenter}
                          onDragEnd={(event) => handleDragEnd(event, null)}
                        >
                          <RecursiveSortable scale={scale} onClick={handleClick} data={data} items={data} parentId={null} onDragEnd={handleDragEnd} />
                        </DndContext>
                    </div>

                </div>
            </div>
            
        </div>
      </div>
    </>
  ) : (
    <Navigate to="/projects" />
  )
}