import { Avatar, AvatarImage, AvatarFallback } from "../../../../components/avatar";
import { Sun, Plus } from "lucide-react";
import { Link } from "react-router-dom";
import {
    Dialog,
    DialogClose,
    DialogContent,
    DialogDescription,
    DialogFooter,
    DialogHeader,
    DialogTitle,
    DialogTrigger,
} from "../../../../components/dialog";
import { Button } from "../../../../components/button";
import { Input } from "../../../../components/input";
import { useState } from "react";
import TopBarsLink from "./TopBarsLink";
import ToggleThemeButton from "../ToogleThemeButton";
import { ChevronDown } from "lucide-react";
import { ChevronRight } from "lucide-react";
import { Label } from "../../../../components/label"
import { RadioGroup, RadioGroupItem } from "../../../../components/radio-group";
import {
    Tooltip,
    TooltipContent,
    TooltipProvider,
    TooltipTrigger,
} from "../../../../components/tooltip"


interface ProjectTopBarProps {
    name: string,
    profilPic: string,
    handleClick: (prompt: string, theme: string) => void;
}

export default function ProjectTopBar({ name, profilPic, handleClick }: ProjectTopBarProps) {
    const [input, setInput] = useState('');
    const [dialogOpen, setDialogOpen] = useState(false);
    const [error, setError] = useState('');
    const [isOpen, setIsOpen] = useState(false);
    const [selectedOption, setSelectedOption] = useState('default');

    const colorMap: Record<string, string[]> = {
        default: ['hsla(240, 4.8%, 95.9%)', 'hsla(240, 5.9%, 90%)', 'hsla(240, 5.9%, 10%)', 'hsla(240, 10%, 3.9%)'],
        ruby: ['hsla(240, 4.8%, 95.9%)', 'hsla(240, 5.9%, 90%)', 'hsla(346.8, 77.2%, 49.8%)', 'hsla(240, 10%, 3.9%)'],
        sapphire: ['hsla(240, 4.8%, 95.9%)', 'hsla(240, 5.9%, 90%)', 'hsla(221.2, 83.2%, 53.3%)', 'hsla(240, 10%, 3.9%)'],
        emerald: ['hsla(240, 4.8%, 95.9%)', 'hsla(240, 5.9%, 90%)', 'hsla(142, 86%, 28%)', 'hsla(240, 10%, 3.9%)'],
        windows: ['hsla(240, 100%, 50%)', 'hsla(0, 0%, 60%)', 'hsla(0, 0%, 80%)', 'hsla(240, 10%, 3.9%)'],
        daylight: ['hsla(36, 64%, 57%)', 'hsla(36, 45%, 60%)', 'hsla(36, 45%, 70%)', 'hsla(36, 45%, 15%)'],
        midnight: ['hsla(240, 0%, 13%)', 'hsla(240, 6%, 20%)', 'hsla(240, 0%, 90%)', 'hsla(60, 5%, 90%)'],
        pastel: ['hsla(211, 86%, 70%)', 'hsla(210, 40%, 80%)', 'hsla(308, 56%, 85%)', 'hsla(339, 20%, 20%)'],
        deepsea: ['hsla(198, 70%, 50%)', 'hsla(210, 50%, 40%)', 'hsla(200, 100%, 28%)', 'hsla(180, 100%, 90%)'],
    };

    const handleSelection = (option: string) => {
        console.log(option)
        setSelectedOption(option);
        setIsOpen(false);
    };

    const toggleDiv = () => setIsOpen(!isOpen);

    const handleKeyDown = (event: React.KeyboardEvent<HTMLInputElement>) => {
        if (event.key === 'Enter') {
            if (input.trim() === '') {
                setError('Project name cannot be empty');
                return;
            }

            localStorage.setItem("selectedTheme", selectedOption)
    
            handleClick(input, selectedOption);
    
            setInput('');
            setError('');
    
            setDialogOpen(false);
        }
    };

    return (
        <div className='bg-background border-b w-full h-16 flex items-center justify-between px-4'>
            <button className="flex items-center gap-2.5">
                <Avatar>
                    <AvatarImage src={profilPic} />
                    <AvatarFallback>LF</AvatarFallback>
                </Avatar>
                <span className="font-semibold">{name}</span>
            </button>
            <TopBarsLink />
            <div className='flex items-center gap-4'>
                <ToggleThemeButton />
                <Dialog open={dialogOpen} onOpenChange={setDialogOpen}>
                    <DialogTrigger asChild>
                        <button 
                            className="inline-flex gap-x-2 items-center justify-center whitespace-nowrap rounded-md text-sm font-medium ring-offset-background transition-colors focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-ring focus-visible:ring-offset-2 disabled:pointer-events-none disabled:opacity-50 bg-blue-500 text-white hover:bg-blue-500/90 h-9 px-3"
                            onClick={() => setDialogOpen(true)}
                        >
                            New Project
                            <Plus strokeWidth={1.50} size={20}/>
                        </button>
                    </DialogTrigger>
                    <DialogContent className="w-1/2">
                        <DialogHeader>
                            <DialogTitle>New Project</DialogTitle>
                            <DialogDescription>
                                Create a new project in one click.
                            </DialogDescription>
                        </DialogHeader>
                            <div className='bg-background border border-input rounded-lg p-3 gap-3 flex items-center'>
                                    <div>
                                        <button
                                            onClick={toggleDiv}
                                            className='flex h-auto w-fit items-center gap-0.5 rounded-md bg-muted p-1 pl-1.5 text-gray-400 transition-colors focus:outline-none hover:bg-gray-700/70 disabled:pointer-events-none disabled:opacity-60'
                                        >
                                            <div className='flex items-center overflow-hidden h-5 w-24 rounded border border-gs-gray-alpha-400'>
                                                {colorMap[selectedOption].map((color, index) => (
                                                    <div
                                                        key={index}
                                                        className='relative h-full flex-1'
                                                        style={{ backgroundColor: color }}
                                                    ></div>
                                                ))}
                                            </div>
                                            <ChevronDown />
                                        </button>
                                        {isOpen && (
                                            <div className="absolute mt-1 z-50 overflow-hidden rounded-md border bg-popover px-1 py-3 text-sm text-popover-foreground shadow-md animate-in fade-in-0 zoom-in-95">
                                                <RadioGroup defaultValue={selectedOption}>
                                                    <div className="flex items-center space-x-2 justify-between w-[280px] transition duration-200 hover:bg-border p-1 rounded-md">
                                                        <label htmlFor="r1" className="flex items-center space-x-2 justify-between w-full cursor-pointer" onClick={() => handleSelection('default')}>
                                                            <div className="flex items-center space-x-2">
                                                                <RadioGroupItem value="default" id="r1" />
                                                                <Label htmlFor="r1">Default</Label>
                                                            </div>
                                                            <div className='flex items-center overflow-hidden h-5 w-24 rounded border border-gs-gray-alpha-400'>
                                                                <div className="relative h-full flex-1" style={{ "--color": "hsla(240, 4.8%, 95.9%)", backgroundColor: "var(--color)" } as React.CSSProperties}></div>
                                                                <div className="relative h-full flex-1" style={{ "--color": "hsla(240 5.9% 90%)", backgroundColor: "var(--color)" } as React.CSSProperties}></div>
                                                                <div className="relative h-full flex-1" style={{ "--color": "hsla(240 5.9% 10%)", backgroundColor: "var(--color)" } as React.CSSProperties}></div>
                                                                <div className="relative h-full flex-1" style={{ "--color": "hsla(240 10% 3.9%)", backgroundColor: "var(--color)" } as React.CSSProperties}></div>
                                                            </div>
                                                        </label>
                                                    </div>

                                                    <div className="flex items-center space-x-2 justify-between w-[280px] transition duration-200 hover:bg-border p-1 rounded-md">
                                                        <label htmlFor="r2" className="flex items-center space-x-2 justify-between w-full cursor-pointer" onClick={() => handleSelection('ruby')}>
                                                            <div className="flex items-center space-x-2">
                                                                <RadioGroupItem value="ruby" id="r2" />
                                                                <Label htmlFor="r2">Ruby</Label>
                                                            </div>
                                                            <div className='flex items-center overflow-hidden h-5 w-24 rounded border border-gs-gray-alpha-400'>
                                                                <div className="relative h-full flex-1" style={{ "--color": "hsla(240, 4.8%, 95.9%)", backgroundColor: "var(--color)" } as React.CSSProperties}></div>
                                                                <div className="relative h-full flex-1" style={{ "--color": "hsla(240 5.9% 90%)", backgroundColor: "var(--color)" } as React.CSSProperties}></div>
                                                                <div className="relative h-full flex-1" style={{ "--color": "hsla(346.8 77.2% 49.8%)", backgroundColor: "var(--color)" } as React.CSSProperties}></div>
                                                                <div className="relative h-full flex-1" style={{ "--color": "hsla(240 10% 3.9%)", backgroundColor: "var(--color)" } as React.CSSProperties}></div>
                                                            </div>
                                                        </label>
                                                    </div>

                                                    <div className="flex items-center space-x-2 justify-between w-[280px] transition duration-200 hover:bg-border p-1 rounded-md">
                                                        <label htmlFor="r3" className="flex items-center space-x-2 justify-between w-full cursor-pointer" onClick={() => handleSelection('sapphire')}>
                                                            <div className="flex items-center space-x-2">
                                                                <RadioGroupItem value="sapphire" id="r3" />
                                                                <Label htmlFor="r3">Sapphire</Label>
                                                            </div>
                                                            <div className='flex items-center overflow-hidden h-5 w-24 rounded border border-gs-gray-alpha-400'>
                                                                <div className="relative h-full flex-1" style={{ "--color": "hsla(240, 4.8%, 95.9%)", backgroundColor: "var(--color)" } as React.CSSProperties}></div>
                                                                <div className="relative h-full flex-1" style={{ "--color": "hsla(240 5.9% 90%)", backgroundColor: "var(--color)" } as React.CSSProperties}></div>
                                                                <div className="relative h-full flex-1" style={{ "--color": "hsla(221.2 83.2% 53.3%)", backgroundColor: "var(--color)" } as React.CSSProperties}></div>
                                                                <div className="relative h-full flex-1" style={{ "--color": "hsla(240 10% 3.9%)", backgroundColor: "var(--color)" } as React.CSSProperties}></div>
                                                            </div>
                                                        </label>
                                                    </div>
                                                    <div className="flex items-center space-x-2 justify-between w-[280px] transition duration-200 hover:bg-border p-1 rounded-md">
                                                        <label htmlFor="r4" className="flex items-center space-x-2 justify-between w-full cursor-pointer" onClick={() => handleSelection('emerald')}>
                                                            <div className="flex items-center space-x-2">
                                                                <RadioGroupItem value="emerald" id="r4" />
                                                                <Label htmlFor="r4">Emerald</Label>
                                                            </div>
                                                            <div className='flex items-center overflow-hidden h-5 w-24 rounded border border-gs-gray-alpha-400'>
                                                                <div className="relative h-full flex-1" style={{ "--color": "hsla(240, 4.8%, 95.9%)", backgroundColor: "var(--color)" } as React.CSSProperties}></div>
                                                                <div className="relative h-full flex-1" style={{ "--color": "hsla(240 5.9% 90%)", backgroundColor: "var(--color)" } as React.CSSProperties}></div>
                                                                <div className="relative h-full flex-1" style={{ "--color": "hsla(142 86% 28%)", backgroundColor: "var(--color)" } as React.CSSProperties}></div>
                                                                <div className="relative h-full flex-1" style={{ "--color": "hsla(240 10% 3.9%)", backgroundColor: "var(--color)" } as React.CSSProperties}></div>
                                                            </div>
                                                        </label>
                                                    </div>
                                                    <div className="flex items-center space-x-2 justify-between w-[280px] transition duration-200 hover:bg-border p-1 rounded-md">    
                                                        <label htmlFor="r5" className="flex items-center space-x-2 justify-between w-full cursor-pointer" onClick={() => handleSelection('windows')}>
                                                            <div className="flex items-center space-x-2">
                                                                <RadioGroupItem value="windows" id="r5" />
                                                                <Label htmlFor="r5">Windows 98</Label>
                                                            </div>
                                                            <div className='flex items-center overflow-hidden h-5 w-24 rounded border border-gs-gray-alpha-400'>
                                                                <div className="relative h-full flex-1" style={{ "--color": "hsla(240 100% 50%)", backgroundColor: "var(--color)" } as React.CSSProperties}></div>
                                                                <div className="relative h-full flex-1" style={{ "--color": "hsla(0 0% 60%)", backgroundColor: "var(--color)" } as React.CSSProperties}></div>
                                                                <div className="relative h-full flex-1" style={{ "--color": "hsla(0 0% 80%)", backgroundColor: "var(--color)" } as React.CSSProperties}></div>
                                                                <div className="relative h-full flex-1" style={{ "--color": "hsla(240 10% 3.9%)", backgroundColor: "var(--color)" } as React.CSSProperties}></div>
                                                            </div>
                                                        </label>
                                                    </div>
                                                    <div className="flex items-center space-x-2 justify-between w-[280px] transition duration-200 hover:bg-border p-1 rounded-md">
                                                        <label htmlFor="r6" className="flex items-center space-x-2 justify-between w-full cursor-pointer" onClick={() => handleSelection('daylight')}>
                                                            <div className="flex items-center space-x-2">
                                                                <RadioGroupItem value="daylight" id="r6" />
                                                                <Label htmlFor="r6">Daylight</Label>
                                                            </div>
                                                            <div className='flex items-center overflow-hidden h-5 w-24 rounded border border-gs-gray-alpha-400'>
                                                                <div className="relative h-full flex-1" style={{ "--color": "hsla(36 64% 57%)", backgroundColor: "var(--color)" } as React.CSSProperties}></div>
                                                                <div className="relative h-full flex-1" style={{ "--color": "hsla(36 45% 60%)", backgroundColor: "var(--color)" } as React.CSSProperties}></div>
                                                                <div className="relative h-full flex-1" style={{ "--color": "hsla(36 45% 70%)", backgroundColor: "var(--color)" } as React.CSSProperties}></div>
                                                                <div className="relative h-full flex-1" style={{ "--color": "hsla(36 45% 15%)", backgroundColor: "var(--color)" } as React.CSSProperties}></div>
                                                            </div>
                                                        </label>
                                                    </div>
                                                    <div className="flex items-center space-x-2 justify-between w-[280px] transition duration-200 hover:bg-border p-1 rounded-md">
                                                        <label htmlFor="r7" className="flex items-center space-x-2 justify-between w-full cursor-pointer" onClick={() => handleSelection('midnight')}>
                                                            <div className="flex items-center space-x-2">
                                                                <RadioGroupItem value="midnight" id="r7" />
                                                                <Label htmlFor="r7">Midnight</Label>
                                                            </div>
                                                            <div className='flex items-center overflow-hidden h-5 w-24 rounded border border-gs-gray-alpha-400'>
                                                                <div className="relative h-full flex-1" style={{ "--color": "hsla(240 0% 13%)", backgroundColor: "var(--color)" } as React.CSSProperties}></div>
                                                                <div className="relative h-full flex-1" style={{ "--color": "hsla(240 6% 20%)", backgroundColor: "var(--color)" } as React.CSSProperties}></div>
                                                                <div className="relative h-full flex-1" style={{ "--color": "hsla(240 0% 90%)", backgroundColor: "var(--color)" } as React.CSSProperties}></div>
                                                                <div className="relative h-full flex-1" style={{ "--color": "hsla(60 5% 90%", backgroundColor: "var(--color)" } as React.CSSProperties}></div>
                                                            </div>
                                                        </label>
                                                    </div>
                                                    <div className="flex items-center space-x-2 justify-between w-[280px] transition duration-200 hover:bg-border p-1 rounded-md">
                                                        <label htmlFor="r8" className="flex items-center space-x-2 justify-between w-full cursor-pointer" onClick={() => handleSelection('pastel')}>
                                                            <div className="flex items-center space-x-2">
                                                                <RadioGroupItem value="pastel" id="r8" />
                                                                <Label htmlFor="r8">Pastel</Label>
                                                            </div>
                                                            <div className='flex items-center overflow-hidden h-5 w-24 rounded border border-gs-gray-alpha-400'>
                                                                <div className="relative h-full flex-1" style={{ "--color": "hsla(211 86% 70%)", backgroundColor: "var(--color)" } as React.CSSProperties}></div>
                                                                <div className="relative h-full flex-1" style={{ "--color": "hsla(210 40% 80%)", backgroundColor: "var(--color)" } as React.CSSProperties}></div>
                                                                <div className="relative h-full flex-1" style={{ "--color": "hsla(308 56% 85%)", backgroundColor: "var(--color)" } as React.CSSProperties}></div>
                                                                <div className="relative h-full flex-1" style={{ "--color": "hsla(339 20% 20%)", backgroundColor: "var(--color)" } as React.CSSProperties}></div>
                                                            </div>
                                                        </label>
                                                    </div>
                                                    <div className="flex items-center space-x-2 justify-between w-[280px] transition duration-200 hover:bg-border p-1 rounded-md">
                                                        <label htmlFor="r9" className="flex items-center space-x-2 justify-between w-full cursor-pointer" onClick={() => handleSelection('deepsea')}>
                                                            <div className="flex items-center space-x-2">
                                                                <RadioGroupItem value="deepsea" id="r9" />
                                                                <Label htmlFor="r9">Deep Sea</Label>
                                                            </div>
                                                            <div className='flex items-center overflow-hidden h-5 w-24 rounded border border-gs-gray-alpha-400'>
                                                                <div className="relative h-full flex-1" style={{ "--color": "hsla(198 70% 50%)", backgroundColor: "var(--color)" } as React.CSSProperties}></div>
                                                                <div className="relative h-full flex-1" style={{ "--color": "hsla(210 50% 40%)", backgroundColor: "var(--color)" } as React.CSSProperties}></div>
                                                                <div className="relative h-full flex-1" style={{ "--color": "hsla(200 100% 28%)", backgroundColor: "var(--color)" } as React.CSSProperties}></div>
                                                                <div className="relative h-full flex-1" style={{ "--color": "hsla(180 100% 90%)", backgroundColor: "var(--color)" } as React.CSSProperties}></div>
                                                            </div>
                                                        </label>
                                                    </div>
                                                        
                                                </RadioGroup>
                                            </div>
                                        )}
                                    </div>
                                <input 
                                    className='flex h-10 w-full rounded-md bg-background px-3 py-2 text-md placeholder:text-muted-foreground focus:outline-none disabled:cursor-not-allowed disabled:opacity-50'
                                    placeholder='A landing page for a chatbot application'
                                    value={input}
                                    onChange={(e) => {
                                        setInput(e.target.value);
                                        setError('');
                                    }}
                                    onKeyDown={handleKeyDown}
                                />
                                <ChevronRight className='text-muted-foreground cursor-pointer' size={30} />
                            </div>
                            {/* <div className="flex items-center gap-4">
                                <div className="grid flex-1 gap-2">
                                    <Label htmlFor="project-name" className="sr-only">
                                        Project Name
                                    </Label>
                                    <Input
                                        id="project-name"
                                        placeholder="Type your project name."
                                        value={projectName}
                                        onChange={(e) => {
                                            setProjectName(e.target.value);
                                            setError('');
                                        }}
                                    />
                                    {error && (
                                        <p className="text-red-500 text-sm mt-1">{error}</p>
                                    )}
                                </div>
                                <button 
                                    className="inline-flex gap-x-2 items-center justify-center whitespace-nowrap rounded-md text-sm font-medium ring-offset-background transition-colors focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-ring focus-visible:ring-offset-2 disabled:pointer-events-none disabled:opacity-50 bg-blue-500 text-primary-foreground hover:bg-blue-500/90 h-9 px-3"
                                    onClick={handleCreateProject}
                                >
                                    Create Project
                                </button>
                            </div> */}
                    </DialogContent>
                </Dialog>
            </div>
        </div>
    );
}



