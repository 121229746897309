import { CustomCSSProperties } from "../Theme"

export const layout = (data: CustomCSSProperties) => {
  const fontHeading = data['--font-heading'].replace(/['"]+/g, '');
  const fontBody = data['--font-body'].replace(/['"]+/g, '');

  const importFonts = fontHeading !== fontBody
    ? `
import { ${fontHeading} } from 'next/font/google';
import { ${fontBody} } from 'next/font/google';`
    : `
import { ${fontHeading} } from 'next/font/google';`;

  const applyFonts =  `
const fontHeading = ${fontHeading}({
  subsets: ['latin'],
  display: 'swap',
  variable: '--font-heading',
});

const fontBody = ${fontBody}({
  subsets: ['latin'],
  display: 'swap',
  variable: '--font-body',
});`;

  return `// This is the root layout component for your Next.js app.
// Learn more: https://nextjs.org/docs/app/building-your-application/routing/pages-and-layouts#root-layout-required
${importFonts}
import { cn } from '@/lib/utils';
import './globals.css';

${applyFonts}

export default function Layout({ children }) {
  return (
    <html lang="en">
      <body 
        className={cn(
          'antialiased',
          fontHeading.variable,
          fontBody.variable
        )}
      >
        {children}
      </body>
    </html>
  );
}`;
}