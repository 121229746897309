import { CSS } from "@dnd-kit/utilities";
import { useSortable } from "@dnd-kit/sortable";
import React, { useEffect } from "react";

// interface SortableItemProps {
//     id: string;
//     text?: string;
//     htmlTag: string;
//     className: string[];
//     onClick: (id: string) => void;
//     children?: React.ReactNode;
// }

// function SortableItem({ id, text, htmlTag, className, onClick, children }: SortableItemProps) {
//     const { attributes, listeners, setNodeRef, transform, transition, isDragging } = useSortable({ id });

//     const style = {
//         transform: CSS.Translate.toString(transform),
//         transition,
//         zIndex: isDragging ? 1000 : 'auto',
//         opacity: isDragging ? 0.8 : 1,
//     };

//     useEffect(() => {
//         const outline = document.getElementById('hovered-element');
//         const outlineSelected = document.getElementById('selected-element');
//         if (outline && outlineSelected && isDragging) {
//             outline.style.display = 'none';
//             outlineSelected.style.display = 'none';
//         }
//     }, [isDragging]);

//     const handleMouseEnter = (e: React.MouseEvent) => {
//         if (!isDragging) {
//             const rect = e.currentTarget.getBoundingClientRect();
//             const outline = document.getElementById('hovered-element');
//             if (outline) {
//                 outline.style.top = `${rect.top + window.scrollY}px`;
//                 outline.style.left = `${rect.left + window.scrollX}px`;
//                 outline.style.width = `${rect.width}px`;
//                 outline.style.height = `${rect.height}px`;
//                 outline.style.display = 'block';
//             }
//         }
//     };


//     const handleClick = (e: React.MouseEvent) => {
//         e.stopPropagation();
//         onClick(id || "");
//         if (!isDragging) {
//         const rect = e.currentTarget.getBoundingClientRect();
//         const outline = document.getElementById('selected-element');
        
//         if (outline) {
//             outline.style.top = `${rect.top + window.scrollY}px`;
//             outline.style.left = `${rect.left + window.scrollX}px`;
//             outline.style.width = `${rect.width}px`;
//             outline.style.height = `${rect.height}px`;
//             outline.style.display = 'block';
//         }
//         }
//     };


//     const handleMouseLeave = () => {
//         const outline = document.getElementById('hovered-element');
//         if (outline) {
//         outline.style.display = 'none';
//         }
//     };

//     const voidElements = ['input', 'img', 'br', 'hr', 'meta', 'link', 'base', 'col', 'command', 'keygen', 'param', 'source', 'track', 'wbr'];

//     return React.createElement(
//         htmlTag,
//         {
//         ref: setNodeRef,
//         style,
//         className: `no-select h-full w-auto cursor-grab flex items-center justify-center ${className.join(' ')}`,
//         ...listeners,
//         ...attributes,
//         onClick: handleClick,
//         onMouseEnter: handleMouseEnter,
//         onMouseLeave: handleMouseLeave,
//         title: `This is ${htmlTag}`,
//         ...(htmlTag === 'input' ? { value: text } : {}),
//         },
//         !voidElements.includes(htmlTag) ? [text, children] : null
//     );
// }

// export default SortableItem

interface SortableItemProps {
    id: string,
    children?: React.ReactNode,
    text?:string,
    scale: number,
    htmlTag: string,
    className: string[],
    src?: string,
    width?: string,
    height?: string,
    onClick: (id: string) => void;
}

function SortableItem({ id, children, text, scale, htmlTag, className, src, width, height, onClick }: SortableItemProps) {
    const { attributes, listeners, setNodeRef, transform, transition, isDragging } = useSortable({ id });

    const style = {
        transform: CSS.Translate.toString({
            x: (transform?.x || 0) * 1/scale,
            y: (transform?.y || 0) * 1/scale,
            scaleX: 1,
            scaleY: 1,
        }),
        transition,
        cursor: 'grab',
        ...(isDragging ? {
            opacity: 1,
            zIndex: 9999,
            position: 'relative',
            backdropFilter: 'blur(10px)',
            boxShadow: '0 4px 8px rgba(0, 0, 0, 0.2)',
            borderRadius: '10px',
        } : {})
    };

    const handleMouseLeave = () => {
        const line = document.getElementById('hovered-element');
        if (line) {
            line.style.display = 'none';
        }
    };
  
    const handleClick = (e: React.MouseEvent) => {
        e.stopPropagation();
        onClick(id || "");
        if (!isDragging) {
            const rect = e.currentTarget.getBoundingClientRect();
            const outline = document.getElementById('selected-element');
            
            if (outline) {
                outline.style.top = `${rect.top + window.scrollY}px`;
                outline.style.left = `${rect.left + window.scrollX}px`;
                outline.style.width = `${rect.width}px`;
                outline.style.height = `${rect.height}px`;
                outline.style.display = 'block';
            }
        }
    };

    const handleMouseEnter = (e: React.MouseEvent) => {
        if (!isDragging) {
            const rect = e.currentTarget.getBoundingClientRect();
            const line = document.getElementById('hovered-element');
            if (line) {
                line.style.top = `${rect.top + window.scrollY}px`;
                line.style.left = `${rect.left + window.scrollX}px`;
                line.style.width = `${rect.width}px`;
                line.style.height = `${rect.height}px`;
                line.style.display = 'block';
            }
        }
    };
  
    const voidElements = ['input', 'img', 'br', 'hr', 'meta', 'link', 'base', 'col', 'command', 'keygen', 'param', 'source', 'track', 'wbr'];
  
  
    return React.createElement(
        htmlTag,
        {
            ref: setNodeRef,
            style: style,
            className: className.join(" ") + " no-select",
            ...(htmlTag === 'input' ? { value: text || '' } : {}),
            ...(htmlTag === 'img' 
              ? { 
                  src: src || '', 
                  width: width || undefined,
                  height: height || undefined
                } 
              : {}),
            id: id,
            ...attributes,
            ...listeners,
            onClick: handleClick,
            onMouseLeave: handleMouseLeave,
            onMouseEnter: handleMouseEnter
        },
        !voidElements.includes(htmlTag) ? [text, children] : null
    );
}

export default SortableItem