import { Avatar, AvatarImage, AvatarFallback } from "../../../../components/avatar"
import { Button } from "../../../../components/button"
import {
  Dialog,
  DialogContent,
  DialogDescription,
  DialogFooter,
  DialogHeader,
  DialogTitle,
  DialogTrigger,
} from "../../../../components/dialog"
import TopBarsLink from "./TopBarsLink"
import { useState, useEffect } from "react"
import DataProps from "../DndKit/utils/DataProps"
import initialData from "../../../../utils/InitialData"
import { baseUrl } from "../../../../baseUrl"
import { Prism as SyntaxHighlighter } from 'react-syntax-highlighter';
import { ScrollArea } from "../../../../components/scroll-area";
import oneDark from "react-syntax-highlighter/dist/cjs/styles/prism/one-dark";
import oneLight from "react-syntax-highlighter/dist/cjs/styles/prism/one-light";
import ToggleThemeButton from "../ToogleThemeButton";
import {
    Tabs,
    TabsContent,
    TabsList,
    TabsTrigger,
} from "../../../../components/tabs";
import Cookies from "js-cookie";
import { CustomCSSProperties } from "../Themes/Theme"
import { defaultTheme } from "../Themes/Theme"
import { themeMap } from "../Themes/Theme"
import { tailwindCode } from "../Themes/ThemeCode/tailwind"
import { global } from "../Themes/ThemeCode/global"
import { layout } from "../Themes/ThemeCode/layout"


export default function PlaygroundTopBar() {
    const projectToken = localStorage.getItem("projectToken") || "";
    const [data, setData] = useState<DataProps[]>(initialData);
    const [isDark, setIsDark] = useState<boolean>(false);
    const [htmlCode, setHtmlCode] = useState<string>("");
    const [globalCode, setGlobalCode] = useState<string>("");
    const [layoutCode, setLayoutCode] = useState<string>("");
    const [name, setName] = useState<string>("");
    const [profil, setProfil] = useState<string>("");
    const [theme, setTheme] = useState<CustomCSSProperties>(defaultTheme);

    const loadThemeFromLocalStorage = () => {
        const savedThemeName = localStorage.getItem('selectedTheme') || "default";
        console.log(themeMap[savedThemeName])
        if (savedThemeName && themeMap[savedThemeName]) {
        setTheme(themeMap[savedThemeName]);
        }
        else {
        setTheme(defaultTheme);
        }
    };
    
    const getProjectData = () => {
        const token = Cookies.get("userToken") || ""
        fetch(baseUrl + '/get_project_from_token', {
            headers: {
                "tokenUser": token,
                "projectToken": projectToken
            }
        })
        .then(response => response.json())
        .then(resData => {
            try {
                setData(resData[0]["data"]);
            } catch (error) {
                console.error(error);
            }
        });
    };


    useEffect(() => {
        loadThemeFromLocalStorage()
        setName(Cookies.get("name") || "")
        setProfil(Cookies.get("profil") || "")
        getProjectData();
    }, [])

    function renderSectionToString(section: any): string {
        const voidElements = ['input', 'img', 'br', 'hr', 'meta', 'link', 'base', 'col', 'command', 'keygen', 'param', 'source', 'track', 'wbr'];
    
        const children = !voidElements.includes(section.htmlTag)
            ? [
                section.text || '',
                ...(section.children ? section.children.map((child: any) => renderSectionToString(child)) : []),
            ].filter(Boolean).join('')
            : '';
    
        const attrs = {
            className: section.className?.join(' ') || '',
            ...(section.htmlTag === 'input' ? { value: section.text || '' } : {}),
            ...(section.htmlTag === 'img' 
                ? { 
                    src: section.src || '', 
                    width: section.width || undefined,
                    height: section.height || undefined
                  } 
                : {}),
        };
    
        const attrString = Object.entries(attrs)
            .map(([key, value]) => value ? `${key}="${value}"` : '')
            .filter(Boolean)
            .join(' ');
    
        return voidElements.includes(section.htmlTag)
            ? `<${section.htmlTag} ${attrString.trim()}/>`
            : `<${section.htmlTag} ${attrString.trim()}>${children}</${section.htmlTag}>`;
    }
    

    function indentHTML(htmlString: string) {
        const tabSize = 4;
        let indentLevel = 0;
    
        return htmlString.replace(/(<\/?[^>]+>|[^<>]+)/g, (match) => {
            if (match.startsWith("</")) {
                indentLevel--;
            }
    
            const result = `${"\n" + " ".repeat(indentLevel * tabSize)}${match.trim()}`;
    
            if (match.startsWith("<") && !match.startsWith("</") && !match.endsWith("/>")) {
                indentLevel++;
            }
    
            return result;
        });
    }
    

    function generateHTML() {
        const html = data.map(section => renderSectionToString(section)).join('');
    
        const indentedHTML = indentHTML(html);
    
        const indentedWithTwoTabs = indentedHTML
            .split('\n')
            .map(line => '        ' + line)
            .join('\n');
    
        setHtmlCode(`export function Page() { \n\treturn(${indentedWithTwoTabs}\n\t)\n}`);
    }

    const generateCode = () => {
        generateHTML()
        createGlobal()
        createLayout()
        setIsDark(localStorage.getItem("theme") === "true")
    }


    function createGlobal() {
        const cssString = global(theme);
        setGlobalCode(cssString)
    }

    function createLayout() {
        const layoutString = layout(theme);
        setLayoutCode(layoutString)
    }
    
    function copyToClipboard(text: string) {
        navigator.clipboard.writeText(text).then(() => {
        }).catch(err => {
            console.error('Erreur lors de la copie dans le presse-papiers :', err);
        });
    }

    return(
        <div className='bg-background border-b w-full h-16 flex items-center justify-between px-4'>
            <button className="flex items-center gap-2.5">
                <Avatar>
                    <AvatarImage src={profil} />
                    <AvatarFallback>LF</AvatarFallback>
                </Avatar>
                <span className="font-semibold">{name}</span>
            </button>
            
            <TopBarsLink />
            <div className='flex items-center gap-2.5'>
                <ToggleThemeButton />
                <a href={"/preview/" + localStorage.getItem("projectToken")} target="_blank" rel="noopener noreferrer">
                    <Button variant="outline" size="sm">Preview Page</Button>
                </a>
                <Dialog>
                    <DialogTrigger asChild>
                        <Button size="sm" onClick={generateCode}>Export Code</Button>
                    </DialogTrigger>
                    <DialogContent>
                        <DialogHeader>
                        <DialogTitle>Code</DialogTitle>
                        </DialogHeader>
                        <Tabs defaultValue="page" className="">
                            <TabsList className="grid w-full grid-cols-4">
                                <TabsTrigger value="page">Page.js</TabsTrigger>
                                <TabsTrigger value="tailwind">tailwind.config.js</TabsTrigger>
                                <TabsTrigger value="global">global.css</TabsTrigger>
                                <TabsTrigger value="layout">layout.jsx</TabsTrigger>
                            </TabsList>
                            <TabsContent value="page">
                                <ScrollArea className="h-[60vh] w-[100vh]">
                                    <SyntaxHighlighter language="jsx" wrapLongLines style={isDark ? oneDark : oneLight}>
                                        {htmlCode}
                                    </SyntaxHighlighter>
                                </ScrollArea>
                            </TabsContent>
                            <TabsContent value="tailwind">
                                <ScrollArea className="h-[60vh] w-[100vh]">
                                    <SyntaxHighlighter language="js" wrapLongLines style={isDark ? oneDark : oneLight}>
                                        {tailwindCode}
                                    </SyntaxHighlighter>
                                </ScrollArea>
                            </TabsContent>
                            <TabsContent value="global">
                                <ScrollArea className="h-[60vh] w-[100vh]">
                                    <SyntaxHighlighter language="css" wrapLongLines style={isDark ? oneDark : oneLight}>
                                        {globalCode}
                                    </SyntaxHighlighter>
                                </ScrollArea>
                            </TabsContent>
                            <TabsContent value="layout">
                                <ScrollArea className="h-[60vh] w-[100vh]">
                                    <SyntaxHighlighter language="jsx" wrapLongLines style={isDark ? oneDark : oneLight}>
                                        {layoutCode}
                                    </SyntaxHighlighter>
                                </ScrollArea>
                            </TabsContent>
                        </Tabs>
                            
                        <DialogFooter>
                            <Button onClick={() => copyToClipboard(htmlCode)}>Copy Code</Button>
                        </DialogFooter>
                    </DialogContent>
                    </Dialog>
            </div>
        </div>
    )
}
