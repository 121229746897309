import generateUniqueId from "../../../utils/generateId"
import DataProps from "../../ListFast/Components/DndKit/utils/DataProps"

const HeroCentred: () => DataProps = () => {
    return {
        "id": generateUniqueId(),
        "index": 4,
        "htmlTag": "section",
        "children": [
            {
                "id": generateUniqueId(),
                "index": 0,
                "htmlTag": "div",
                "children": [
                    {
                        "id": generateUniqueId(),
                        "text": "Unlock the Power of Web Development.",
                        "index": 0,
                        "htmlTag": "div",
                        "children": [
                            {
                                "id": generateUniqueId(),
                                "text": "Read more →",
                                "index": 0,
                                "htmlTag": "a",
                                "children": [],
                                "className": [
                                    "font-semibold",
                                    "text-foreground",
                                    "ml-2"
                                ]
                            }
                        ],
                        "className": [
                            "rounded-full",
                            "px-3",
                            "py-1",
                            "text-sm",
                            "leading-6",
                            "text-muted-foreground",
                            "ring-1",
                            "ring-border"
                        ]
                    }
                ],
                "className": [
                    "hidden",
                    "sm:mb-8",
                    "sm:flex",
                    "sm:justify-center"
                ]
            },
            {
                "id": generateUniqueId(),
                "text": "The most intuitive no code app.",
                "index": 1,
                "htmlTag": "h1",
                "children": [],
                "className": [
                    "text-center",
                    "font-bold",
                    "text-6xl"
                ]
            },
            {
                "id": generateUniqueId(),
                "text": "Test your concept without wasting time, validate your idea in minutes with our fast waiting list solution, then build when you know people are interested.",
                "index": 2,
                "htmlTag": "p",
                "children": [],
                "className": [
                    "text-muted-foreground",
                    "max-w-2xl",
                    "text-sm",
                    "sm:text-md",
                    "md:text-xl",
                    "mt-6",
                    "text-center"
                ]
            },
            {
                "id": generateUniqueId(),
                "index": 3,
                "htmlTag": "div",
                "children": [
                    {
                        "id": generateUniqueId(),
                        "text": "Get started",
                        "index": 0,
                        "htmlTag": "button",
                        "children": [],
                        "className": [
                            "rounded-md",
                            "py-2",
                            "px-10",
                            "text-primary-foreground",
                            "bg-primary",
                            "font-semibold",
                            "hover:bg-opacity-80",
                            "transition"
                        ]
                    },
                    {
                        "id": generateUniqueId(),
                        "text": "Learn more",
                        "index": 1,
                        "htmlTag": "button",
                        "children": [],
                        "className": [
                            "rounded-md",
                            "py-2",
                            "px-10",
                            "border",
                            "border-input",
                            "bg-background",
                            "font-semibold",
                            "hover:bg-opacity-80",
                            "transition"
                        ]
                    }
                ],
                "className": [
                    "mt-10",
                    "flex",
                    "items-center",
                    "justify-center",
                    "gap-x-6"
                ]
            }
        ],
        "className": [
            "flex",
            "pt-20",
            "flex-col",
            "items-center",
            "text-foreground",
            "justify-center"
        ]
    }
}

export default HeroCentred
