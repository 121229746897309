import generateUniqueId from "../../../utils/generateId"
import DataProps from "../../ListFast/Components/DndKit/utils/DataProps"

const LinkFooter: () => DataProps = () => {
    return {
        "id": generateUniqueId(),
        "index": 7,
        "htmlTag": "footer",
        "children": [
            {
                "id": generateUniqueId(),
                "index": 0,
                "text": "© 2024 Linkfast Inc. All rights reserved.",
                "htmlTag": "p",
                "children": [],
                "className": [
                    "text-xs",
                ]
            },
            {
                "id": generateUniqueId(),
                "index": 1,
                "htmlTag": "nav",
                "children": [
                    {
                        "id": generateUniqueId(),
                        "text": "Terms of Service",
                        "index": 0,
                        "htmlTag": "a",
                        "children": [],
                        "className": [
                            "text-xs",
                            "text-muted-foreground",
                            "hover:underline",
                            "underline-offset-4"
                        ]
                    },
                    {
                        "id": generateUniqueId(),
                        "text": "Privacy Policy",
                        "index": 1,
                        "htmlTag": "a",
                        "children": [],
                        "className": [
                            "text-xs",
                            "text-muted-foreground",
                            "hover:underline",
                            "underline-offset-4"
                        ]
                    },
                    {
                        "id": generateUniqueId(),
                        "index": 2,
                        "text": "Cookie Policy",
                        "htmlTag": "a",
                        "children": [],
                        "className": [
                            "text-xs",
                            "text-muted-foreground",
                            "hover:underline",
                            "underline-offset-4"
                        ]
                    }
                ],
                "className": [
                    "flex",
                    "items-center",
                    "gap-4",
                    "sm:gap-6"
                ]
            }
        ],
        "className": [
            "p-10",
            "max-w-7xl",
            "flex",
            "flex-col",
            "items-center",
            "justify-between",
            "gap-4",
            "text-foreground",
            "md:flex-row"
        ]
    }
}

export default LinkFooter
