import { Link } from "react-router-dom"
import { useGoogleLogin } from '@react-oauth/google';
import { useNavigate } from "react-router-dom";
import { baseUrl } from "../../../baseUrl";
import Cookies from "js-cookie"

interface SignInButtonProps {
    setDialogOpen: React.Dispatch<React.SetStateAction<boolean>>;
}
  
export default function SignInButton({ setDialogOpen }: SignInButtonProps) {
  const login = useGoogleLogin({
    onSuccess: async (tokenResponse) => {
      const data = {
        google_token: tokenResponse.access_token,
      };
  
      console.log('Token:', data);
  
      try {
        const userInfoResponse = await fetch('https://www.googleapis.com/oauth2/v3/userinfo', {
          headers: {
            Authorization: `Bearer ${tokenResponse.access_token}`,
          },
        });
  
        if (!userInfoResponse.ok) {
          throw new Error('Failed to fetch user info');
        }
  
        const userInfo = await userInfoResponse.json();
  
        const { email, name, picture } = userInfo;
        fetch(baseUrl + '/create_user', {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            'email': email,
            'name': name,
            'profilPic': picture
          },
        })
          .then((response) => {
            if (!response.ok) {
              throw new Error('Failed to join the waitlist');
            }
            return response.json();
          })
          .then((data) => {
            Cookies.set("userToken", data.token, { secure: true });
            setDialogOpen(false)
          })
          .catch((error) => {
            console.error('Error fetching data:', error);
          });
        console.log({ email, name, picture });
      } catch (error) {
        console.error('Error fetching user info: ', error);
      }
    },
  });
  return (
      <>
        <div onClick={() => login()} className='flex text-sm md:text-lg gap-2 items-center justify-center text-white hover:bg-blue-600 transition-all bg-blue-500 py-3 px-8 rounded-md font-bold cursor-pointer'>
          <svg xmlns="http://www.w3.org/2000/svg" height="14" width="13.34375" viewBox="0 0 488 512"><path fill="#ffffff" d="M488 261.8C488 403.3 391.1 504 248 504 110.8 504 0 393.2 0 256S110.8 8 248 8c66.8 0 123 24.5 166.3 64.9l-67.5 64.9C258.5 52.6 94.3 116.6 94.3 256c0 86.5 69.1 156.6 153.7 156.6 98.2 0 135-70.4 140.8-106.9H248v-85.3h236.1c2.3 12.7 3.9 24.9 3.9 41.4z"/></svg>
            with Google
        </div>
      </>
  )
}
