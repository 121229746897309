"use client"

import { useState, useEffect } from "react"
import { Dialog, DialogTrigger, DialogContent, DialogHeader, DialogTitle, DialogDescription, DialogFooter } from "../../../components/dialog"
import { Link, useNavigate } from "react-router-dom"
import { Plus, ChevronRight, Grid2X2 } from "lucide-react"
import { baseUrl } from "../../../baseUrl"
import { Switch } from "../../../components/switch"
import ProjectTopBar from "../Components/TopBars/ProjectsTopBar"
import Preview from "../Preview/Preview"
import Cookies from "js-cookie"
import SignInButton from "../SignInLogi/SignInButton"

interface ProjectDataCard {
  data: string;
  name: string;
  token: string;
  date: string;
}

export default function Projects() {
  const [Projects, setProjects] = useState<ProjectDataCard[]>([]);
  const [profilUrl, setProfilUrl] = useState<string>("null");
  const [name, setName] = useState<string>("Unlogged");
  const [dialogOpen, setDialogOpen] = useState<boolean>(false);

  useEffect(() => {
    const token = Cookies.get("userToken");

    
    if (token) {
      loadProjectList(token)
      getUserInfo(token)
    } else {
      setTimeout(() => setDialogOpen(true), 500)
    }
  }, []);

  const navigate = useNavigate();
  const handleNavigate = (projectToken: string) => {
    navigate('/playground/' + projectToken);
  }

  const loadTokenOnLocalStorage = (token: string) => {
    localStorage.setItem("projectToken", token);
  }

  const loadProjectList = (token: string) => {
    fetch(baseUrl + '/get_projects_list', { headers: {"tokenUser" : token} })
    .then(response => {
      if (!response.ok) {
        console.error("Error project list");
      }
      return response.json();
    })
    .then(data => {
      console.log("Data received from API:", data);
      if (Array.isArray(data)) {
        setProjects(data);
      } else {
        console.error("Projects data is not an array");
        setProjects([]);
      }
    })
    .catch(error => console.log('Error fetching data:', error));

    console.log(Projects.length)
  };

  const newProject = (prompt: string, theme: string) => {
    navigate('/playground/loading');

    fetch(baseUrl + '/new_project', { 
      method: 'POST',
      
      headers: {"Content-Type": "application/json", "tokenUser" : Cookies.get("userToken") || "", "prompt": prompt, "theme": theme} 
    })
        .then(response => response.json())
        .then(data => {
            const projectToken = data.project_token;
            localStorage.setItem("projectToken", projectToken)
            handleNavigate(projectToken)
            loadProjectList(Cookies.get("userToken") || "");
        })
        .catch(error => console.error('Error fetching data:', error));
  }

  useEffect(() => {
    getUserInfo(Cookies.get("userToken") || "")
    loadProjectList(Cookies.get("userToken") || "")
  }, [dialogOpen])
  
  const getUserInfo = (token: string) => {
    const data = { credentials: token };
    fetch(baseUrl + '/check_cookies', { 
      method: 'POST',
      headers: {
        "Content-Type": "application/json"
      },
      body: JSON.stringify(data) 
    })
    .then(response => {
      if (!response.ok) {
        setDialogOpen(true);
      }
      return response.json();
    })
    .then(data => {
      try {
        setName(data["name"]);
        Cookies.set("name", data["name"], { secure: true });
        console.log(data)
        setProfilUrl(data["profil"]);
        Cookies.set("profil", data["profil"], { secure: true });
      } catch (error) {
        console.error("Error processing response data:", error);
      }
    })
    .catch(error => {
      console.error("Fetch error:", error);
    });
}
  console.log(typeof(Projects))
  return (
      <div>
          <Dialog open={dialogOpen}>
            <DialogContent>
              <DialogHeader>
                <DialogTitle>Login</DialogTitle>
                <DialogDescription>Please log in to find and save your projects.</DialogDescription>
              </DialogHeader>
              <div>
                  <SignInButton setDialogOpen={setDialogOpen}/>
              </div>
            </DialogContent>
          </Dialog>
          <ProjectTopBar name={name} profilPic={profilUrl} handleClick={newProject}/>
          <div className="flex bg-background">
            <div className="p-7 border-r h-[100vh]">
              
              <ul className="flex flex-col gap-2">
                <li className="bg-accent cursor-pointer py-1.5 px-3 pr-24 flex items-center gap-3 rounded-md">
                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 576 512"
                    className="w-4 h-4">
                      <path 
                        fill="#3b82f6"
                        d="M88.7 223.8L0 375.8 0 96C0 60.7 28.7 32 64 32l117.5 0c17 0 33.3 6.7 45.3 18.7l26.5 26.5c12 12 28.3 18.7 45.3 18.7L416 96c35.3 0 64 28.7 64 64l0 32-336 0c-22.8 0-43.8 12.1-55.3 31.8zm27.6 16.1C122.1 230 132.6 224 144 224l400 0c11.5 0 22 6.1 27.7 16.1s5.7 22.2-.1 32.1l-112 192C453.9 474 443.4 480 432 480L32 480c-11.5 0-22-6.1-27.7-16.1s-5.7-22.2 .1-32.1l112-192z"/></svg>
                  <span className="text-md font-medium">Projects</span>
                </li>
                <a href="https://discord.gg/sps49AECCk" target="_blank" rel="noopener noreferrer">
                  <li className="transition-colors cursor-pointer hover:bg-accent py-1.5 px-3 pr-14 flex items-center gap-3 rounded-md">
                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 640 512"
                      className="w-4 h-4"><path 
                      fill="#3b82f6" d="M524.5 69.8a1.5 1.5 0 0 0 -.8-.7A485.1 485.1 0 0 0 404.1 32a1.8 1.8 0 0 0 -1.9 .9 337.5 337.5 0 0 0 -14.9 30.6 447.8 447.8 0 0 0 -134.4 0 309.5 309.5 0 0 0 -15.1-30.6 1.9 1.9 0 0 0 -1.9-.9A483.7 483.7 0 0 0 116.1 69.1a1.7 1.7 0 0 0 -.8 .7C39.1 183.7 18.2 294.7 28.4 404.4a2 2 0 0 0 .8 1.4A487.7 487.7 0 0 0 176 479.9a1.9 1.9 0 0 0 2.1-.7A348.2 348.2 0 0 0 208.1 430.4a1.9 1.9 0 0 0 -1-2.6 321.2 321.2 0 0 1 -45.9-21.9 1.9 1.9 0 0 1 -.2-3.1c3.1-2.3 6.2-4.7 9.1-7.1a1.8 1.8 0 0 1 1.9-.3c96.2 43.9 200.4 43.9 295.5 0a1.8 1.8 0 0 1 1.9 .2c2.9 2.4 6 4.9 9.1 7.2a1.9 1.9 0 0 1 -.2 3.1 301.4 301.4 0 0 1 -45.9 21.8 1.9 1.9 0 0 0 -1 2.6 391.1 391.1 0 0 0 30 48.8 1.9 1.9 0 0 0 2.1 .7A486 486 0 0 0 610.7 405.7a1.9 1.9 0 0 0 .8-1.4C623.7 277.6 590.9 167.5 524.5 69.8zM222.5 337.6c-29 0-52.8-26.6-52.8-59.2S193.1 219.1 222.5 219.1c29.7 0 53.3 26.8 52.8 59.2C275.3 311 251.9 337.6 222.5 337.6zm195.4 0c-29 0-52.8-26.6-52.8-59.2S388.4 219.1 417.9 219.1c29.7 0 53.3 26.8 52.8 59.2C470.7 311 447.5 337.6 417.9 337.6z"/></svg>
                    <span className="text-md font-medium">Discord</span>
                  </li>
                </a>
                <a href="https://x.com/noshmaster" target="_blank" rel="noopener noreferrer">
                <li className="transition-colors cursor-pointer hover:bg-accent py-1.5 px-3 pr-14 flex items-center gap-3 rounded-md">
                    <svg className="w-4 h-4" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512"><path
                      fill="#3b82f6" d="M459.4 151.7c.3 4.5 .3 9.1 .3 13.6 0 138.7-105.6 298.6-298.6 298.6-59.5 0-114.7-17.2-161.1-47.1 8.4 1 16.6 1.3 25.3 1.3 49.1 0 94.2-16.6 130.3-44.8-46.1-1-84.8-31.2-98.1-72.8 6.5 1 13 1.6 19.8 1.6 9.4 0 18.8-1.3 27.6-3.6-48.1-9.7-84.1-52-84.1-103v-1.3c14 7.8 30.2 12.7 47.4 13.3-28.3-18.8-46.8-51-46.8-87.4 0-19.5 5.2-37.4 14.3-53 51.7 63.7 129.3 105.3 216.4 109.8-1.6-7.8-2.6-15.9-2.6-24 0-57.8 46.8-104.9 104.9-104.9 30.2 0 57.5 12.7 76.7 33.1 23.7-4.5 46.5-13.3 66.6-25.3-7.8 24.4-24.4 44.8-46.1 57.8 21.1-2.3 41.6-8.1 60.4-16.2-14.3 20.8-32.2 39.3-52.6 54.3z"/></svg>
                    <span className="text-md font-medium">Twitter</span>
                  </li>
                </a>
                {/* <li className="transition hover:bg-blue-500/90 text-md text-white font-medium bg-blue-500 cursor-pointer py-1.5 text-center px-20 flex items-center gap-3 rounded-md">Upgrade</li> */}
              </ul>
            </div>
            <div className="w-full flex flex-col gap-5 p-10">
              <h1 className="text-xl font-semibold">Your Projects</h1>
              <div className="grid w-full grid-cols-1 gap-8 sm:grid-cols-[repeat(auto-fill,minmax(300px,1fr))]">
                {Projects && Projects.length == 0 ? (
                  <div>No projects found, try to create one</div>
                ) : (
                  Projects && Projects.map((item, index) => (
                    <Link to={`/playground/${item.token}`} key={index}>
                      <div 
                        className="group"
                        onClick={() => loadTokenOnLocalStorage(item.token)}>
                        <div className="relative flex h-[240px] w-full items-center justify-center overflow-hidden rounded-md bg-zinc-200 transition group-hover:border-blue-700 group-hover:border-2"><img src={"data:image/png;base64," + item.data} alt="Project Image" /></div>
                          <div className="mt-4 flex items-center justify-between">
                            <h1 className="text-lg font-semibold">{item.name}</h1>
                            <p className="text-slate-500 text-sm">{item.date}</p>
                          </div>
                        </div>
                    </Link>
                  ))
                )
                }
                
              </div>

            </div>
          </div>
      </div>
  )
}