import React, { useState, useEffect, useRef, useCallback } from 'react';
import {
  DndContext,
  closestCenter,
  KeyboardSensor,
  MouseSensor,
  TouchSensor,
  useSensor,
  useSensors,
} from '@dnd-kit/core';
import {
  arrayMove,
  sortableKeyboardCoordinates,
} from '@dnd-kit/sortable';
import { Input } from '../../../components/input';
import DataProps from '../Components/DndKit/utils/DataProps';
import SideBar from '../Components/SideBar';
import { ChevronDown, ChevronRight, X } from 'lucide-react';
import { Label } from "../../../components/label"
import { Select, SelectContent, SelectItem, SelectTrigger, SelectValue } from "../../../components/select"
import { ScrollArea } from "../../../components/scroll-area"
import { Separator } from "../../../components/separator"
import PlaygroundTopBar from '../Components/TopBars/PlaygroundTopBar';
import { baseUrl } from '../../../baseUrl';
import initialData from '../../../utils/InitialData';
import { toPng } from 'html-to-image';
import { debounce } from "lodash"
import TailwindClasses from '../../../utils/TailwindClasses';
import { Command } from 'cmdk';
import { Button } from '../../../components/button';
import { Dialog, DialogContent, DialogTrigger } from '../../../components/dialog';
import { ChevronLeft } from 'lucide-react';
import { Textarea } from '../../../components/textarea';
import Header from "../../Templates/Headers/Header";
import HeroCentred from "../../Templates/Hero/HeroCentred";
import HeroWithImage from "../../Templates/Hero/HeroWithImage";
import KeyFeaturesSection from "../../Templates/Features/KeyFeaturesSection";
import FeaturesCentred2x2 from "../../Templates/Features/FeaturesCentred2x2";
import Newsletters from "../../Templates/Newsletters/Newsletters";
import LinkFooter from "../../Templates/Footers/LinkFooter";
import ColFooter from "../../Templates/Footers/4colFooter";
import RecursiveSortable from '../Components/DndKit/RecursiveSortable';
import Cookies from 'js-cookie';
import { Navigate } from 'react-router-dom';


export default function PlaygroundLoading() {
    return (
        <>
          <div id="hovered-element" className="pointer-events-none absolute z-50 hidden border-2 border-blue-500"></div>
          <div id="selected-element" className="pointer-events-none absolute z-50 hidden border-2 border-blue-500"></div>
          <div>
            <PlaygroundTopBar />
            <div className='flex'>
            <div className={`border-r p-2 w-[22%] bg-background`}>
                  <div className={`h-full bg-background`}>
                  <ScrollArea className="h-full w-full">
                    <div className="p-3">
                      <h2 className="text-xl font-semibold mb-4">General Information</h2>
                      {/* <Separator className="my-4" />
                      
                      <section className="mb-6">
                        <div className="space-y-2">
                            <Label htmlFor="site-description">AI Theme and bgColor Creator</Label>
                            <Input id="site-description" placeholder="A yellow dark theme" />
                        </div>
                      </section> */}
                      
                      <Separator className="my-4" />
                      
                      <section className="mb-6">
                        <h3 className="text-sm font-medium mb-2">Typography</h3>
                        <div className="space-y-4">
                          <div className="space-y-2">
                            <Label htmlFor="font-family">Font Family</Label>
                            <Select
                            >
                              <SelectTrigger id="font-family">
                                <SelectValue placeholder="Select a font" />
                              </SelectTrigger>
                              <SelectContent>
                                <SelectItem value="Poppins">Poppins</SelectItem>
                                <SelectItem value="Monserrat">Monserrat</SelectItem>
                                <SelectItem value="Inter">Inter</SelectItem>
                                <SelectItem value="Roboto">Roboto</SelectItem>
                              </SelectContent>
                            </Select>
                          </div>
                        </div>
                      </section>
                      
                      <Separator className="my-4" />
                      
                      <section className="mb-6">
                        <h3 className="text-sm font-medium mb-2">Colors</h3>
                        <div className="space-y-4">
                          <div className="space-y-2">
                            <Label htmlFor="background-bgColor">Background bgColor</Label>
                            <Input 
                            type="color" 
                            id="background-color" 
                            className="h-10 w-full" />
                          </div>
                        </div>
                        <div className="space-y-4">
                          <div className="space-y-2">
                            <Label htmlFor="background-bgColor">Primary Color</Label>
                            <Input 
                            type="color" 
                            id="Dominant-color" 
                            className="h-10 w-full" />
                          </div>
                        </div>
                      </section>
                      
                      <Separator className="my-4" />
                      <button className="h-10 w-full py-2 bg-blue-500 text-white hover:bg-blue-500/90 inline-flex items-center justify-center whitespace-nowrap rounded-md text-sm font-medium ring-offset-background transition-bgColors focus:outline-none focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-ring focus-visible:ring-offset-2 disabled:pointer-events-none disabled:opacity-50">Add Section</button>
                    </div>
                  </ScrollArea>
                </div>
                </div>
                <div className={`w-[78%] overflow-hidden h-[100vh] bg-gray-200`}>
                    <div
                        style={{
                            transform: `scale(${0.8}) translateY(${-50}px)`,
                            transition: 'transform 0.1s ease-in-out',
                        }}
                    >
                        <div 
                        className="min-h-[100vh] p-5 z-0 bg-white"
                        >
                            <div className="flex flex-col justify-center items-center space-y-2 py-20">
                                <div className="rounded-md bg-border h-4 animate-pulse animate-extend-medium"></div>
                                <div className="rounded-md bg-border h-4 animate-pulse animate-extend-large"></div>
                                <div className="rounded-md bg-border h-4 animate-pulse animate-extend-small"></div>
                                <div>
                                    <div className="rounded-md bg-radio h-9 mt-5 animate-pulse animate-extend-button"></div>
                                </div>
                            </div>
                        </div>
    
                    </div>
                </div>
                
            </div>
          </div>
        </>
      )
}