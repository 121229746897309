import { BrowserRouter, Routes, Route } from "react-router-dom";
import Dashboard from "./pages/ListFast/Tracking/DashBoard";
import { LandingPage } from "./pages/LandingPage/LandingPage";
import Projects from "./pages/ListFast/Projects/Projects";
import Sandbox from "./Sandbox";
import Playground from "./pages/ListFast/Playground/Playground";
import Preview from "./pages/ListFast/Preview/Preview";
import { useEffect } from "react";
import PrivateRoutes from "./pages/ListFast/SignInLogi/PrivateRoutes";
import ConnectedRoutes from "./pages/ListFast/SignInLogi/ConnectedRoutes";
import PlaygroundLoading from "./pages/ListFast/Playground/PlaygroundLoading";


export default function App() {
  return (
      <BrowserRouter>
        <Routes>
          <Route index element={<LandingPage />} />
          <Route path="/preview/:token" element={<Preview />} />
          <Route path="/playground/:token" element={<Playground />} />
          <Route path="/playground/loading" element={<PlaygroundLoading />} />
          <Route path="/projects/:token" element={<Projects />} />
          <Route path="/projects" element={<Projects />} />
          <Route path="/sandbox" element={<Sandbox />} />
        </Routes>
      </BrowserRouter>
    );
}