import generateUniqueId from "../../../utils/generateId"
import DataProps from "../../ListFast/Components/DndKit/utils/DataProps"

const FeaturesCentred2x2: () => DataProps = () => {
    return {
      "id": generateUniqueId(),
      "index": 5,
      "htmlTag": "section",
      "children": [
        {
          "id": generateUniqueId(),
          "text": "FEATURES",
          "index": 0,
          "htmlTag": "span",
          "children": [],
          "className": [
            "font-bold",
            "uppercase",
            "text-center",
            "text-primary"
          ]
        },
        {
          "id": generateUniqueId(),
          "text": "Build Your Page And Ship",
          "index": 1,
          "htmlTag": "h2",
          "children": [],
          "className": [
            "max-w-5xl",
            "text-3xl",
            "text-center",
            "font-extrabold",
            "sm:text-4xl",
            "md:text-5xl",
          ]
        },
        {
          "id": generateUniqueId(),
          "text": "Duis turpis tortor, lobortis id metus at, eleifend molestie enim. Suspendisse potenti. Praesent auctor dictum bibendum.",
          "index": 2,
          "htmlTag": "p",
          "children": [],
          "className": [
            "text-muted-foreground",
            "max-w-2xl",
            "text-sm",
            "sm:text-md",
            "md:text-xl",
            "text-center"
          ]
        },
        {
          "id": generateUniqueId(),
          "index": 3,
          "htmlTag": "div",
          "children": [
            {
              "id": generateUniqueId(),
              "index": 0,
              "htmlTag": "div",
              "children": [
                {
                  "id": generateUniqueId(),
                  "text": "No code",
                  "index": 0,
                  "htmlTag": "span",
                  "children": [],
                  "className": [
                    "text-primary-foreground",
                    "py-1",
                    "px-3",
                    "rounded-full",
                    "font-semibold",
                    "bg-primary"
                  ]
                },
                {
                  "id": generateUniqueId(),
                  "text": "Mauris pulvinar elementum scelerisque. Nunc varius rutrum pharetra. Nam metus diam, tincidunt a dolor sed, vestibulum suscipit quam.",
                  "index": 1,
                  "htmlTag": "p",
                  "children": [],
                  "className": [
                    "text-center",
                    "text-muted-foreground"
                  ]
                }
              ],
              "className": [
                "flex",
                "flex-col",
                "gap-3",
                "items-center"
              ]
            },
            {
              "id": generateUniqueId(),
              "index": 1,
              "htmlTag": "div",
              "children": [
                {
                  "id": generateUniqueId(),
                  "text": "Scalability",
                  "index": 0,
                  "htmlTag": "span",
                  "children": [],
                  "className": [
                    "text-primary-foreground",
                    "py-1",
                    "px-3",
                    "rounded-full",
                    "font-semibold",
                    "bg-primary"
                  ]
                },
                {
                  "id": generateUniqueId(),
                  "text": "Aenean malesuada ultricies tortor, accumsan cursus massa fringilla non. Proin venenatis, orci ut vulputate viverra, mi nibh venenatis sapien.",
                  "index": 1,
                  "htmlTag": "p",
                  "children": [],
                  "className": [
                    "text-center",
                    "text-muted-foreground"
                  ]
                }
              ],
              "className": [
                "flex",
                "flex-col",
                "gap-3",
                "items-center"
              ]
            },
            {
              "id": generateUniqueId(),
              "index": 2,
              "htmlTag": "div",
              "children": [
                {
                  "id": generateUniqueId(),
                  "text": "Accessibility",
                  "index": 0,
                  "htmlTag": "span",
                  "children": [],
                  "className": [
                    "text-primary-foreground",
                    "py-1",
                    "px-3",
                    "rounded-full",
                    "font-semibold",
                    "bg-primary"
                  ]
                },
                {
                  "id": generateUniqueId(),
                  "text": "Vestibulum sodales augue eget mauris, vitae tincidunt turpis vestibulum. Nulla varius lorem ac iaculis cursus.",
                  "index": 1,
                  "htmlTag": "p",
                  "children": [],
                  "className": [
                    "text-center",
                    "text-muted-foreground"
                  ]
                }
              ],
              "className": [
                "flex",
                "flex-col",
                "gap-3",
                "items-center"
              ]
            },
            {
              "id": generateUniqueId(),
              "index": 3,
              "htmlTag": "div",
              "children": [
                {
                  "id": generateUniqueId(),
                  "text": "Automatic",
                  "index": 0,
                  "htmlTag": "span",
                  "children": [],
                  "className": [
                    "text-primary-foreground",
                    "py-1",
                    "px-3",
                    "rounded-full",
                    "font-semibold",
                    "bg-primary"
                  ]
                },
                {
                  "id": generateUniqueId(),
                  "text": "Nam eu sapien sodales, tincidunt velit condimentum, gravida arcu. Aliquam porttitor vel justo id dictum.",
                  "index": 1,
                  "htmlTag": "p",
                  "children": [],
                  "className": [
                    "text-center",
                    "text-muted-foreground"
                  ]
                }
              ],
              "className": [
                "flex",
                "flex-col",
                "gap-3",
                "items-center"
              ]
            }
          ],
          "className": [
            "mx-auto",
            "text-center",
            "mt-16",
            "max-w-2xl",
            "sm:mt-20",
            "lg:mt-24",
            "lg:max-w-4xl",
            "grid",
            "grid-cols-1",
            "gap-x-8",
            "gap-y-10",
            "lg:grid-cols-2",
            "lg:gap-y-16"
          ]
        }
      ],
      "className": [
        "flex",
        "py-20",
        "flex-col",
        "items-center",
        "justify-center",
        "text-foreground",
        "gap-3"
      ]
    }
}


export default FeaturesCentred2x2