import { Link } from "react-router-dom"
import { useEffect, useState } from "react"

export default function TopBarsLink() {
    const [projectToken, setProjectToken] = useState<string | null>(null);

    useEffect(() => {
        setProjectToken(localStorage.getItem("projectToken"))
    }, [])

    return(
        <ul className='flex-grow text-center flex justify-center gap-x-10 font-medium'>
            <Link to={"/projects"}>
                <li className='hover:text-blue-500'>Projects</li>
            </Link>
            <Link to={`${projectToken ? "/playground/" + projectToken : ""}`}>
                <li className={`${projectToken ? "hover:text-blue-500": "text-zinc-300"}`}>Playground</li>
            </Link>
            {/* <Link to={`${projectToken ? "/tracking/" + projectToken : ""}`}>
                <li className={`${projectToken ? "hover:text-blue-500": "text-zinc-300"}`}>Tracking</li>
            </Link> */}
        </ul>
    )
}