import Header from "./Header"
import React, { useRef } from 'react';
import data from '../../data.json';
import { FirstSection } from "./sections/FirstSection"
import { SecondSection } from "./sections/SecondSection"
import { ThirdSection } from "./ThirdSection"
import { FAQList } from "./sections/FAQList"
import Waitlist from "./sections/Waitlist"
import { Footer } from "./sections/footer"
import { Outlet, Navigate } from 'react-router-dom';


export function LandingPage() {
    return(
        <Navigate to="/projects" />
    )
    // const waitlistRef = useRef<HTMLDivElement | null>(null);

    // // Function to scroll to the waitlist section
    // const scrollToWaitlist = () => {
    //     if (waitlistRef.current) {
    //     waitlistRef.current.scrollIntoView({ behavior: 'smooth' });
    //     }
    // };
    // return(
    //     <div>
    //         <header className="flex justify-between p-5 mx-auto sm:px-6 lg:px-8 max-w-7xl w-full">
    //         <h1 className="text-white text-xl font-extrabold cursor-pointer">
    //             {data.name}
    //             </h1>
    //             <button 
    //                 onClick={scrollToWaitlist}
    //                 className='text-white transition-all duration-500 bg-gradient-to-r to-violet-800 from-blue-700 bg-size-150 bg-pos-0 hover:bg-pos-100 py-2 px-8 rounded-md font-bold cursor-pointer'>Join waitlist
    //             </button>
    //         </header>
    //         <div className="pb-24">
    //             <div className="bg-gradient-to-t from-[#0f0f0f] from-10% via-[#19191f] via-30% to-background to-80%">
    //             <div className="pt-40 mx-auto px-4 sm:px-6 lg:px-8 max-w-7xl w-full">
    //                 <div className="flex flex-col items-center">
    //                 <h1 className="text-white max-w-5xl text-4xl text-center font-extrabold sm:text-5xl md:text-6xl">
    //                     {data.title}
    //                 </h1>
    //                 <p className="text-subtitle max-w-2xl text-sm sm:text-md md:text-xl mt-6 text-center">
    //                     {data.subtitle}
    //                 </p>
    //                 <button
    //                     onClick={scrollToWaitlist}
    //                     className="text-white text-sm md:text-lg cursor-pointer transition-all duration-500 bg-gradient-to-r to-violet-800 from-blue-700 bg-size-150 bg-pos-0 hover:bg-pos-100 py-3 px-8 rounded-md font-bold mt-10"
    //                 >
    //                     Get started
    //                 </button>
    //                 <div className="pt-14 md:pt-32">
    //                     <img className="rounded-xl" src="/home.png" alt="home" />
    //                 </div>
    //                 </div>
    //             </div>
    //             </div>
    //             <FirstSection />
    //             <SecondSection />
    //             {/* <ThirdSection /> */}
    //             <Waitlist ref={waitlistRef} />
    //             <FAQList />
    //             {/* <Footer /> */}
    //         </div>
    //     </div>
    // )
}