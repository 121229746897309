import { Sun, Moon } from "lucide-react"
import { useState, useEffect } from "react"

export default function ToggleThemeButton() {
    const getTheme = localStorage.getItem("theme") === "true";
    const [isDark, setIsDark] = useState<boolean>(getTheme);

    const toggleTheme = () => {
        const newTheme = !isDark;
        setIsDark(newTheme);
        localStorage.setItem("theme", newTheme.toString());
        if (newTheme) {
            document.documentElement.classList.add("dark");
        } else {
            document.documentElement.classList.remove("dark");
        }
    };

    useEffect(() => {
        if (isDark) {
            document.documentElement.classList.add("dark");
        } else {
            document.documentElement.classList.remove("dark");
        }
    }, [isDark]);

    return (
        <button className="focus:outline-none" onClick={toggleTheme}>
            {isDark ? <Moon /> : <Sun />}
        </button>
    );
}
