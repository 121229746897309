import { CustomCSSProperties } from "../Theme"

const convertThemeToCSS = (data: CustomCSSProperties): string => {
  return Object.entries(data)
    .map(([key, value]) => `        ${key}: ${value};`)
    .join('\n');
};

export const global = (data: CustomCSSProperties) => {
    return `@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
    :root {
${convertThemeToCSS(data)}
    }
}

@layer base {
    * {
        @apply border-border;
    }

    body {
        @apply bg-background text-foreground font-body;
    }

    h1, h2, h3, h4, h5, h6 {
        @apply font-heading;
    }
}`
}