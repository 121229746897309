import DataProps from "../pages/ListFast/Components/DndKit/utils/DataProps";

const initialData: DataProps[] = [
    {
      "id": "jf5fwaguayoerrqkkv524qtq3wo8prwh",
      "index": 0,
      "htmlTag": "div",
      "children": [],
      "className": []
    }
  ];

export default initialData