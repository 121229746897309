import generateUniqueId from "../../../utils/generateId"
import DataProps from "../../ListFast/Components/DndKit/utils/DataProps"

const Header: () => DataProps = () => {
    return {
        "id": generateUniqueId(),
        "index": 8,
        "htmlTag": "header",
        "children": [
            {
                "id": generateUniqueId(),
                "index": 0,
                "text": "ListFast",
                "htmlTag": "h1",
                "children": [],
                "className": [
                    "text-xl",
                    "font-extrabold",
                    "cursor-pointer",
                    "text-foreground"
                ]
            },
            {
                "id": generateUniqueId(),
                "index": 1,
                "htmlTag": "div",
                "children": [
                    {
                        "id": generateUniqueId(),
                        "index": 0,
                        "htmlTag": "nav",
                        "children": [
                            {
                                "id": generateUniqueId(),
                                "text": "Home",
                                "index": 0,
                                "htmlTag": "a",
                                "children": [],
                                "className": [
                                    "font-semibold",
                                    "text-foreground"
                                ]
                            },
                            {
                                "id": generateUniqueId(),
                                "text": "About",
                                "index": 1,
                                "htmlTag": "a",
                                "children": [],
                                "className": [
                                    "font-semibold",
                                    "text-foreground"
                                ]
                            },
                            {
                                "id": generateUniqueId(),
                                "text": "Services",
                                "index": 2,
                                "htmlTag": "a",
                                "children": [],
                                "className": [
                                    "font-semibold",
                                    "text-foreground"
                                ]
                            },
                            {
                                "id": generateUniqueId(),
                                "text": "Contact",
                                "index": 3,
                                "htmlTag": "a",
                                "children": [],
                                "className": [
                                    "font-semibold",
                                    "text-foreground"
                                ]
                            }
                        ],
                        "className": [
                            "flex",
                            "gap-6",
                            "font-semibold",
                            "text-foreground"
                        ]
                    },
                    {
                        "id": generateUniqueId(),
                        "text": "Join waitlist",
                        "index": 1,
                        "htmlTag": "button",
                        "children": [],
                        "className": [
                            "rounded-md",
                            "py-2",
                            "px-10",
                            "text-primary-foreground",
                            "bg-primary",
                            "font-semibold",
                            "hover:bg-opacity-80",
                            "transition"
                        ]
                    }
                ],
                "className": [
                    "flex",
                    "items-center",
                    "gap-12"
                ]
            }
        ],
        "className": [
            "flex",
            "justify-between",
            "items-center",
            "mx-auto",
            "p-5",
            "sm:px-6",
            "lg:px-8",
            "max-w-7xl",
            "text-foreground",
            "w-full"
        ]
    }
}

export default Header
