import { baseUrl } from "../../../baseUrl";
import React, { useState, useEffect, useRef } from "react";
import DataProps from "../Components/DndKit/utils/DataProps";
import initialData from "../../../utils/InitialData";
import Cookies from "js-cookie";
import { Navigate } from "react-router-dom";
import { CustomCSSProperties } from "../Components/Themes/Theme";
import { defaultTheme } from "../Components/Themes/Theme";
import { themeMap } from "../Components/Themes/Theme";

export default function Preview() {
    const projectToken = localStorage.getItem("projectToken") || ""
    const [data, setData] = useState<DataProps[]>(initialData);
    const token = Cookies.get('userToken');
    const url = baseUrl + '/check_cookies'; 
    const [isAuthenticated, setIsAuthenticated] = useState<boolean>(false);
    const [isLoading, setIsLoading] = useState<boolean>(true);
    const [theme, setTheme] = useState<CustomCSSProperties>(defaultTheme);


    const loadThemeFromLocalStorage = () => {
      const savedThemeName = localStorage.getItem('selectedTheme') || "default";
      console.log(themeMap[savedThemeName])
      if (savedThemeName && themeMap[savedThemeName]) {
        setTheme(themeMap[savedThemeName]);
      }
      else {
        setTheme(defaultTheme);
      }
    };

    const getProjectData = () => {
      const token = Cookies.get("userToken") || ""
        fetch(baseUrl + '/get_project_from_token', { headers: {"tokenUser" : token, "projectToken" : projectToken} })
      .then(response => response.json())
      .then(resData => {
        try {
          setData(resData[0]["data"]);
        } catch (error) {
          console.error(error)
        }
      })
    };

    useEffect(() => {
      getProjectData();
      loadThemeFromLocalStorage()
  }, []);


    function renderSection(section: any) {
        const voidElements = ['input', 'img', 'br', 'hr', 'meta', 'link', 'base', 'col', 'command', 'keygen', 'param', 'source', 'track', 'wbr'];

        return React.createElement(
            section.htmlTag,
            {
                key: section.id,
                className: section.className?.join(' ') || '',
                ...(section.htmlTag === 'input' ? { value: section.text || '' } : {}),
                ...(section.htmlTag === 'img' 
                  ? { 
                      src: section.src || '', 
                      width: section.width || undefined,
                      height: section.height || undefined
                    } 
                  : {}),
            },
            !voidElements.includes(section.htmlTag)
                ? [
                    section.text,
                    section.children && section.children.map((child: any) => renderSection(child)),
                  ]
                : null
        );
    }

    useEffect(() => {
        const checkAuth = async () => {
            const data = { credentials: token };

            try {
                const response = await fetch(url, {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                    },
                    body: JSON.stringify(data),
                });

                if (response.ok) {
                    setIsAuthenticated(true);
                } else {
                    setIsAuthenticated(false);
                }
            } catch (error) {
                setIsAuthenticated(false);
            } finally {
                setIsLoading(false);
            }
        };

        checkAuth();
    }, [token, url]);

    if (isLoading) {
        return <div></div>;
    }

    return isAuthenticated ? (
        <div className="playground bg-background"
          style={theme}
        >
            {data.map((section) => renderSection(section))}
        </div>
    ) : (
      <Navigate to="/projects" />
    )
}