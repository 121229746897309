import generateUniqueId from "../../../utils/generateId";
import DataProps from "../../ListFast/Components/DndKit/utils/DataProps";

const colFooter: () => DataProps = () => {
    return {
      "id": generateUniqueId(),
      "index": 7,
      "htmlTag": "section",
      "children": [
        {
          "id": generateUniqueId(),
          "index": 0,
          "htmlTag": "div",
          "children": [
            {
              "id": generateUniqueId(),
              "index": 0,
              "htmlTag": "div",
              "children": [
                {
                  "id": generateUniqueId(),
                  "text": "About Us",
                  "index": 0,
                  "htmlTag": "h3",
                  "children": [],
                  "className": [
                    "font-bold",
                    "mb-2"
                  ]
                },
                {
                  "id": generateUniqueId(),
                  "index": 0,
                  "htmlTag": "ul",
                  "children": [
                    {
                      "id": generateUniqueId(),
                      "index": 0,
                      "htmlTag": "li",
                      "children": [
                        {
                          "id": generateUniqueId(),
                          "text": "Our Story",
                          "index": 0,
                          "htmlTag": "a",
                          "children": [],
                          "className": []
                        }
                      ],
                      "className": []
                    },
                    {
                      "id": generateUniqueId(),
                      "index": 1,
                      "htmlTag": "li",
                      "children": [
                        {
                          "id": generateUniqueId(),
                          "text": "Team",
                          "index": 0,
                          "htmlTag": "a",
                          "children": [],
                          "className": []
                        }
                      ],
                      "className": []
                    },
                    {
                      "id": generateUniqueId(),
                      "index": 2,
                      "htmlTag": "li",
                      "children": [
                        {
                          "id": generateUniqueId(),
                          "text": "Careers",
                          "index": 0,
                          "htmlTag": "a",
                          "children": [],
                          "className": []
                        }
                      ],
                      "className": []
                    },
                    {
                      "id": generateUniqueId(),
                      "index": 3,
                      "htmlTag": "li",
                      "children": [
                        {
                          "id": generateUniqueId(),
                          "text": "Mission & Values",
                          "index": 0,
                          "htmlTag": "a",
                          "children": [],
                          "className": []
                        }
                      ],
                      "className": []
                    }
                  ],
                  "className": []
                }
              ],
              "className": []
            },
            {
              "id": generateUniqueId(),
              "index": 1,
              "htmlTag": "div",
              "children": [
                {
                  "id": generateUniqueId(),
                  "text": "Services",
                  "index": 0,
                  "htmlTag": "h3",
                  "children": [],
                  "className": [
                    "font-bold",
                    "mb-2"
                  ]
                },
                {
                  "id": generateUniqueId(),
                  "index": 0,
                  "htmlTag": "ul",
                  "children": [
                    {
                      "id": generateUniqueId(),
                      "index": 0,
                      "htmlTag": "li",
                      "children": [
                        {
                          "id": generateUniqueId(),
                          "text": "Web Development",
                          "index": 0,
                          "htmlTag": "a",
                          "children": [],
                          "className": []
                        }
                      ],
                      "className": []
                    },
                    {
                      "id": generateUniqueId(),
                      "index": 1,
                      "htmlTag": "li",
                      "children": [
                        {
                          "id": generateUniqueId(),
                          "text": "Consulting",
                          "index": 0,
                          "htmlTag": "a",
                          "children": [],
                          "className": []
                        }
                      ],
                      "className": []
                    },
                    {
                      "id": generateUniqueId(),
                      "index": 2,
                      "htmlTag": "li",
                      "children": [
                        {
                          "id": generateUniqueId(),
                          "text": "Design Services",
                          "index": 0,
                          "htmlTag": "a",
                          "children": [],
                          "className": []
                        }
                      ],
                      "className": []
                    },
                    {
                      "id": generateUniqueId(),
                      "index": 3,
                      "htmlTag": "li",
                      "children": [
                        {
                          "id": generateUniqueId(),
                          "text": "Custom Solutions",
                          "index": 0,
                          "htmlTag": "a",
                          "children": [],
                          "className": []
                        }
                      ],
                      "className": []
                    }
                  ],
                  "className": []
                }
              ],
              "className": []
            },
            {
              "id": generateUniqueId(),
              "index": 2,
              "htmlTag": "div",
              "children": [
                {
                  "id": generateUniqueId(),
                  "text": "Support",
                  "index": 0,
                  "htmlTag": "h3",
                  "children": [],
                  "className": [
                    "font-bold",
                    "mb-2"
                  ]
                },
                {
                  "id": generateUniqueId(),
                  "index": 0,
                  "htmlTag": "ul",
                  "children": [
                    {
                      "id": generateUniqueId(),
                      "index": 0,
                      "htmlTag": "li",
                      "children": [
                        {
                          "id": generateUniqueId(),
                          "text": "FAQs",
                          "index": 0,
                          "htmlTag": "a",
                          "children": [],
                          "className": []
                        }
                      ],
                      "className": []
                    },
                    {
                      "id": generateUniqueId(),
                      "index": 1,
                      "htmlTag": "li",
                      "children": [
                        {
                          "id": generateUniqueId(),
                          "text": "Documentation",
                          "index": 0,
                          "htmlTag": "a",
                          "children": [],
                          "className": []
                        }
                      ],
                      "className": []
                    },
                    {
                      "id": generateUniqueId(),
                      "index": 2,
                      "htmlTag": "li",
                      "children": [
                        {
                          "id": generateUniqueId(),
                          "text": "Community Forum",
                          "index": 0,
                          "htmlTag": "a",
                          "children": [],
                          "className": []
                        }
                      ],
                      "className": []
                    },
                    {
                      "id": "8b5c150ccfe7",
                      "index": 3,
                      "htmlTag": "li",
                      "children": [
                        {
                          "id": generateUniqueId(),
                          "text": "Live Chat",
                          "index": 0,
                          "htmlTag": "a",
                          "children": [],
                          "className": []
                        }
                      ],
                      "className": []
                    }
                  ],
                  "className": []
                }
              ],
              "className": []
            },
            {
              "id": generateUniqueId(),
              "index": 3,
              "htmlTag": "div",
              "children": [
                {
                  "id": "f09cf8242d1b",
                  "text": "Contact",
                  "index": 0,
                  "htmlTag": "h3",
                  "children": [],
                  "className": [
                    "font-bold",
                    "mb-2"
                  ]
                },
                {
                  "id": generateUniqueId(),
                  "index": 0,
                  "htmlTag": "ul",
                  "children": [
                    {
                      "id": generateUniqueId(),
                      "index": 0,
                      "htmlTag": "li",
                      "children": [
                        {
                          "id": "6670d97b610e",
                          "text": "Email Us",
                          "index": 0,
                          "htmlTag": "a",
                          "children": [],
                          "className": []
                        }
                      ],
                      "className": []
                    },
                    {
                      "id": generateUniqueId(),
                      "index": 1,
                      "htmlTag": "li",
                      "children": [
                        {
                          "id": generateUniqueId(),
                          "text": "Call Us",
                          "index": 0,
                          "htmlTag": "a",
                          "children": [],
                          "className": []
                        }
                      ],
                      "className": []
                    },
                    {
                      "id": generateUniqueId(),
                      "index": 2,
                      "htmlTag": "li",
                      "children": [
                        {
                          "id": generateUniqueId(),
                          "text": "Find a Store",
                          "index": 0,
                          "htmlTag": "a",
                          "children": [],
                          "className": []
                        }
                      ],
                      "className": []
                    },
                    {
                      "id": generateUniqueId(),
                      "index": 3,
                      "htmlTag": "li",
                      "children": [
                        {
                          "id": generateUniqueId(),
                          "text": "Follow Us",
                          "index": 0,
                          "htmlTag": "a",
                          "children": [],
                          "className": []
                        }
                      ],
                      "className": []
                    }
                  ],
                  "className": []
                }
              ],
              "className": []
            }
          ],
          "className": [
            "flex",
            "justify-around",
          ]
        }
      ],
      "className": [
        "px-10",
        "text-foreground",
        "py-20"
      ]
    }
    }          

export default colFooter