import generateUniqueId from "../../../utils/generateId"
import DataProps from "../../ListFast/Components/DndKit/utils/DataProps"

const KeyFeaturesSection: () => DataProps = () => {
    return {
        "id": generateUniqueId(),
        "index": 6,
        "htmlTag": "section",
        "children": [
            {
                "id": generateUniqueId(),
                "index": 0,
                "htmlTag": "span",
                "text": "Key Features",
                "children": [],
                "className": [
                    "font-bold",
                    "border",
                    "border-input",
                    "py-1",
                    "px-3",
                    "rounded-sm",
                    "text-muted-foreground"
                ]
            },
            {
                "id": generateUniqueId(),
                "index": 1,
                "text": "Build Your Page And Ship",
                "htmlTag": "h2",
                "children": [],
                "className": [
                    "max-w-5xl",
                    "text-3xl",
                    "text-center",
                    "font-extrabold",
                    "sm:text-4xl",
                    "md:text-5xl",
                ]
            },
            {
                "id": generateUniqueId(),
                "index": 2,
                "text": "Duis turpis tortor, lobortis id metus at, eleifend molestie enim. Suspendisse potenti. Praesent auctor dictum bibendum.",
                "htmlTag": "p",
                "children": [],
                "className": [
                    "text-muted-foreground",
                    "max-w-2xl",
                    "text-sm",
                    "sm:text-md",
                    "md:text-xl",
                    "text-center"
                ]
            },
            {
                "id": generateUniqueId(),
                "index": 3,
                "htmlTag": "div",
                "children": [
                    {
                        "id": generateUniqueId(),
                        "index": 0,
                        "htmlTag": "div",
                        "children": [
                            {
                                "id": generateUniqueId(),
                                "index": 0,
                                "text": "Scalability",
                                "htmlTag": "span",
                                "children": [],
                                "className": [
                                    "text-foreground",
                                    "text-lg",
                                    "font-semibold"
                                ]
                            },
                            {
                                "id": generateUniqueId(),
                                "index": 1,
                                "text": "Aenean malesuada ultricies tortor, accumsan cursus massa fringilla non. Proin venenatis, orci ut vulputate viverra, mi nibh venenatis sapien.",
                                "htmlTag": "p",
                                "children": [],
                                "className": [
                                    "text-muted-foreground"
                                ]
                            },
                            {
                                "id": generateUniqueId(),
                                "index": 2,
                                "text": "Learn more →",
                                "htmlTag": "a",
                                "children": [],
                                "className": [
                                    "text-primary",
                                    "text-sm",
                                    "font-semibold"
                                ]
                            }
                        ],
                        "className": [
                            "flex",
                            "flex-col",
                            "gap-3",
                            "items-start"
                        ]
                    },
                    {
                        "id": generateUniqueId(),
                        "index": 1,
                        "htmlTag": "div",
                        "children": [
                            {
                                "id": generateUniqueId(),
                                "index": 0,
                                "text": "Accessibility",
                                "htmlTag": "span",
                                "children": [],
                                "className": [
                                    "text-foreground",
                                    "text-lg",
                                    "font-semibold"
                                ]
                            },
                            {
                                "id": generateUniqueId(),
                                "index": 1,
                                "text": "Vestibulum sodales augue eget mauris, vitae tincidunt turpis vestibulum. Nulla varius lorem ac iaculis cursus.",
                                "htmlTag": "p",
                                "children": [],
                                "className": [
                                    "text-muted-foreground"
                                ]
                            },
                            {
                                "id": generateUniqueId(),
                                "index": 2,
                                "text": "Learn more →",
                                "htmlTag": "a",
                                "children": [],
                                "className": [
                                    "text-primary",
                                    "text-sm",
                                    "font-semibold"
                                ]
                            }
                        ],
                        "className": [
                            "flex",
                            "flex-col",
                            "gap-3",
                            "items-start"
                        ]
                    },
                    {
                        "id": generateUniqueId(),
                        "index": 2,
                        "htmlTag": "div",
                        "children": [
                            {
                                "id": generateUniqueId(),
                                "index": 0,
                                "text": "No code",
                                "htmlTag": "span",
                                "children": [],
                                "className": [
                                    "text-foreground",
                                    "text-lg",
                                    "font-semibold"
                                ]
                            },
                            {
                                "id": generateUniqueId(),
                                "index": 1,
                                "text": "Mauris pulvinar elementum scelerisque. Nunc varius rutrum pharetra. Nam metus diam, tincidunt a dolor sed, vestibulum suscipit quam.",
                                "htmlTag": "p",
                                "children": [],
                                "className": [
                                    "text-muted-foreground"
                                ]
                            },
                            {
                                "id": generateUniqueId(),
                                "index": 2,
                                "text": "Learn more →",
                                "htmlTag": "a",
                                "children": [],
                                "className": [
                                    "text-primary",
                                    "text-sm",
                                    "font-semibold"
                                ]
                            }
                        ],
                        "className": [
                            "flex",
                            "flex-col",
                            "gap-3",
                            "items-start"
                        ]
                    },
                    {
                        "id": generateUniqueId(),
                        "index": 3,
                        "htmlTag": "div",
                        "children": [
                            {
                                "id": generateUniqueId(),
                                "index": 0,
                                "text": "Automatic",
                                "htmlTag": "span",
                                "children": [],
                                "className": [
                                    "text-foreground",
                                    "text-lg",
                                    "font-semibold"
                                ]
                            },
                            {
                                "id": generateUniqueId(),
                                "index": 1,
                                "text": "Nam eu sapien sodales, tincidunt velit condimentum, gravida arcu. Aliquam porttitor vel justo id dictum.",
                                "htmlTag": "p",
                                "children": [],
                                "className": [
                                    "text-muted-foreground"
                                ]
                            },
                            {
                                "id": generateUniqueId(),
                                "index": 2,
                                "text": "Learn more →",
                                "htmlTag": "a",
                                "children": [],
                                "className": [
                                    "text-primary",
                                    "text-sm",
                                    "font-semibold"
                                ]
                            }
                        ],
                        "className": [
                            "flex",
                            "flex-col",
                            "gap-3",
                            "items-start"
                        ]
                    }
                ],
                "className": [
                    "mx-auto",
                    "mt-16",
                    "max-w-2xl",
                    "sm:mt-20",
                    "lg:mt-24",
                    "lg:max-w-4xl",
                    "grid",
                    "grid-cols-1",
                    "gap-x-8",
                    "gap-y-10",
                    "lg:grid-cols-2",
                    "lg:gap-y-16"
                ]
            }
        ],
        "className": [
            "flex",
            "py-20",
            "flex-col",
            "items-center",
            "justify-center",
            "text-foreground",
            "gap-3"
        ]
    }
}

export default KeyFeaturesSection
